<template>
  <div >


    <!--  轮播图-->
    <div class="banner" style="margin-bottom: 45px">
      <el-carousel :interval="5000" arrow="never" style="height: 8rem;">
        <el-carousel-item style="height: 8rem;" class="banner-item">
          <img src="../../assets/kyy/banner01.png">
        </el-carousel-item>
        <el-carousel-item style="height: 8rem;" class="banner-item">
          <img src="../../assets/kyy/temporaryPic/bann2.png">
        </el-carousel-item>
        <el-carousel-item style="height:8rem;" class="banner-item">
          <img src="../../assets/kyy/temporaryPic/banner5.jpg">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="clearFloat"></div>

    <!--    核心业务-->
    <div class="business">
      <span class="business-title" style="margin-bottom: 0.2rem;margin-top: 1.133rem">核心业务</span>
      <span class="business-content"
            style="margin-bottom: 0.2rem">提供领先、可靠、多场景的应用解决方案安全、稳定、确定</span>
      <!--      四个li-->
      <ul style="display: flex;justify-content: center;padding-left: 0">
        <li class="content-footer-item">
          <img style="width: 2.667rem;height: 2.667rem;" src="../../assets/kyy/temporaryPic/w1.png" alt="">
          <span class="footer-title">智算中心</span>
          <span class="footer-content">智算中心咨询
              /规划/建设/运营/运维</span>
        </li>
        <li class="content-footer-item">
          <img style="width: 2.667rem;height: 2.667rem;" src="../../assets/kyy/temporaryPic/w2.png" alt="">
          <span class="footer-title">算力网络</span>
          <span class="footer-content">云互联、超算互联、智算互联</span>
        </li>
        <li class="content-footer-item">
          <img style="width: 2.667rem;height: 2.667rem;" src="../../assets/kyy/temporaryPic/w3.png" alt="">
          <span class="footer-title">算力服务</span>
          <span class="footer-content">融合云、HPC、AI算力、信创</span>
        </li>
        <li class="content-footer-item">
          <img style="width: 2.667rem;height: 2.667rem;" src="../../assets/kyy/temporaryPic/w4.png" alt="">
          <span class="footer-title">应用解决方案</span>
          <span class="footer-content">企业多场景云服务、高校科学计算、大模型架构及应用</span>
        </li>
      </ul>
      <span class="business-title" style="margin-top: 1.1rem;margin-bottom: 0.4rem">深入业务场景的算网解决方案</span>
      <span class="business-content" style="margin-bottom: 0.5rem">以高水平算网调度解决方案助力各行业高质量发展</span>
      <!--      图片-->
      <div>
        <img class="business-pic" style="width: 16rem;height: 4rem;" src="../../assets/kyy/深入方案bg.png">
      </div>
    </div>





    <!--    企业资讯-->
    <div class="business" style="margin-top:120px">
      <span class="business-title" style="margin-top: 0.667rem;margin-bottom: 0.5rem">企业资讯</span>
      <div>
        <img  style="height: 10.667rem;width: 16rem;cursor: pointer" class="business-pic"
             src="../../assets/kyy/temporaryPic/ff.png">
      </div>
    </div>

    <!--    核心业务-->
    <div class="business" style="height: 7.333rem;margin-top: 45px">
      <span class="business-title" style="margin-bottom: 0.2rem;margin-top: 0.467rem">全国高校算力网络创新论坛</span>
      <span class="business-content" style="width: 16rem;margin-bottom: 0.333rem;line-height: 1.5">由江苏未来网络集团主办、中国高等教育学会教育信息化分会指导、开元云（北京）科技有限公司协办的全国高校算力网络创新论坛成功举办，该论坛聚焦高校数字化转型背景下，针对高校科研领域的新要求，分享算力网络最新的前沿技术，共话算力网络技术与服务企业产业布局，研讨高校科研需求与算力技术的供应。</span>
      <!--      图片-->
      <div style="display: flex;justify-content: center;align-items: space-between;padding-top: 0.2rem" class="ckl">
        <div style="width: 4.667rem;height: 4rem;">
          <img src="../../assets/kyy/temporaryPic/640.jpg" alt=""/>
        </div>
        <div style="width: 4.667rem;height: 4rem;">
          <img src="../../assets/kyy/temporaryPic/640(1).jpg" alt=""/>
        </div>
        <div style="width: 4.667rem;height: 4rem;">
          <img src="../../assets/kyy/temporaryPic/643.png" alt=""/>
        </div>
      </div>
    </div>


    <!--    合作伙伴-->
    <div class="friends">
      <span class="business-title" style="margin-top: 0.667rem;margin-bottom: 0.2rem">合作伙伴</span>
      <div class="logo-item-s">
        <img src="../../assets/kyy/temporaryPic/logo1.jpg" style="width: 1.333rem;height: 0.867rem;" alt="">
        <img src="../../assets/kyy/temporaryPic/logo8.png" style="width: 1.333rem;height: 0.867rem;" alt="">
        <img src="../../assets/kyy/temporaryPic/logo5.png" style="width: 1.333rem;height: 0.867rem;" alt="">
        <img src="../../assets/kyy/temporaryPic/logo2.jpg" alt="">
        <img src="../../assets/kyy/temporaryPic/lofo3.jpg" alt="">
        <img src="../../assets/kyy/temporaryPic/logo4.jpg" alt="">
        <img src="../../assets/kyy/temporaryPic/logo6.png" alt="">
        <img src="../../assets/kyy/temporaryPic/logo7.jpg" alt="">

      </div>
    </div>

  </div>
</template>

<script type="text/javascript">
window._MICHAT = window._MICHAT || function () {
  (_MICHAT.a = _MICHAT.a || []).push(arguments)
};
_MICHAT("cptid", "3d1ac49f26c5");
_MICHAT("domain", "emdchk.xianshangkefu.net");
(function (m, d, q, j, s) {
  j = d.createElement(q), s = d.getElementsByTagName(q)[0];
  j.async = true;
  j.charset = "UTF-8";
  j.src = ("https:" == document.location.protocol ? "https://" : "http://") + "emdchk.xianshangkefu.net/Web/JS/mivisit.js?_=t";
  s.parentNode.insertBefore(j, s);
})(window, document, "script");
</script>


<script>
// import {getLogoAPI} from "@/api/login";

export default {
  name: "homeIndex",
  data() {
    return {
      myChart: null,
      logoImg: require("../../assets/logo/colorLogo.png"),
      logoText: "开元云(北京)科技有限公司",
      url: window.location.href,
      photos: [],
      photosUrl: [],
      ICP: "京ICP备2022001945号-1",
      address: "农业展览馆13号瑞辰国际中心518B(团结湖地铁站c东南口步行420米)",
      selectData: [],
      fileUrl: null,
      fileName: null,
      title: null,
    }
  },
  created() {
    this.init()
  },
  mounted() {
    this.$nextTick(() => {
      // this.initEchartMap();
      // this.initEcahrtkk()
      // this.initEcahrtkk1()


    })
  },
  methods: {
    visibleChange(row) {
      if (row) {
        this.getDocList()
      }
    },
    handleCommand(row) {
      this.fileUrl = row.file_path,
          this.fileName = row.name
      const url = this.GetQueryString(this.url) + 'idfile?path=' + row.file_path;
      this.downloadFile(url);
    },
    downloadFile(url) {//下载文件
      let a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("download", this.fileName);
      a.setAttribute("target", "_blank");
      let clickEvent = document.createEvent("MouseEvents");
      clickEvent.initEvent("click", true, true);
      a.dispatchEvent(clickEvent);
    },
    // getDocList() {//获取文档列表
    //   getDocListAPI({status: 1}).then(res => {
    //     if (res.status) {
    //       this.selectData = res.data.map(item => {
    //         let xitem = {
    //           id: item.id,
    //           file_path: item.file_path,
    //           // name: item.file_path.slice(item.file_path.lastIndexOf("/") + 1),
    //           name: item.title
    //         }
    //         return xitem
    //       })
    //     } else {
    //       this.$message({
    //         message: res.msg,
    //         type: "error",
    //       });
    //     }
    //   })
    // },
    init() {
      let params = {
        url_link: this.GetQueryString(this.url)
      }
      getLogoAPI(params).then((res) => {
        if (res.status == true && res.data && res.data.length) {
          this.photosUrl = res.data[0];
          if (this.photosUrl.orgname != '业主机构') {
            this.logoImg = this.photosUrl.logo;
            this.logoText = this.photosUrl.orgname;
            this.ICP = this.photosUrl.license_number;
            this.address = this.photosUrl.address;
          }
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    goProduct(path) {
      window.open(path)
    },
    goNewsPage() {
      window.open('http://tmpweb.opencomputing.cn/col.jsp?id=136')
    },
    login() {
      this.$router.push('/login')
    },
    register() {
      this.$router.push('/registrationPage')
    },
    goOtherPage(value) {
      if (value === 'product') {
        window.open('http://tmpweb.opencomputing.cn/col.jsp?id=146')
      } else if (value === 'aboutUs') {
        window.open('http://tmpweb.opencomputing.cn/col.jsp?id=134')
      } else if (value === 'news') {
        window.open('http://tmpweb.opencomputing.cn/col.jsp?id=136')
      } else if (value === 'service') {
        window.open('http://tmpweb.opencomputing.cn/col.jsp?id=137')
      } else if (value === 'solve') {
        window.open('http://tmpweb.opencomputing.cn/col.jsp?id=104')
      }
    },

    dealWithData() {// 初始化地图
      let geoCoordMap = {
        北京: [116.46, 39.92, 100],
        上海: [121.48, 31.22, 200],
      };
      let data = [];
      for (let key in geoCoordMap) {
        data.push({name: key, value: geoCoordMap[key]});
      }
      return data;
    },
    initMapOne() {
      this.myEchart = echarts.init(this.$refs.myEchart);
      let option = {
        tooltip: {
          show: false
        },
        geo: {
          map: "china",
          roam: false,// 一定要关闭拖拽
          zoom: 1.23,
          center: [105, 36], // 调整地图位置
          label: {
            normal: {
              show: false, //关闭省份名展示
              fontSize: "10",
              color: "rgba(0,0,0,0.7)"
            },
            emphasis: {
              show: false
            }
          },
          itemStyle: {
            normal: {
              areaColor: "#0d0059",
              borderColor: "#389dff",
              borderWidth: 1, //设置外层边框
              shadowBlur: 5,
              shadowOffsetY: 8,
              shadowOffsetX: 0,
              shadowColor: "#ecf0f7"
            },
            emphasis: {
              areaColor: "#184cff",
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: 5,
              borderWidth: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            }
          }
        },
        series: [
          {
            type: "map",
            map: "china",
            roam: false,
            zoom: 1.23,
            center: [105, 36],
            // geoIndex: 1,
            // aspectScale: 0.75, //长宽比
            showLegendSymbol: false, // 存在legend时显示
            label: {
              normal: {
                show: false
              },
              emphasis: {
                show: false,
                textStyle: {
                  color: "#fff"
                }
              }
            },
            itemStyle: {
              normal: {
                areaColor: "#0d0059",
                borderColor: "#389dff",
                borderWidth: 0.5
              },
              emphasis: {
                areaColor: "#17008d",
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowBlur: 5,
                borderWidth: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }
          }
        ]
      };
      this.myEchart.setOption(option);

    },
    initMapTwo() {
      this.myEchart = echarts.init(this.$refs.myEchart);
      let dataValue = this.dealWithData();
      // var data1 = dataValue.splice(0, 6);
      let citySchoolMap = [
        {'北京': ['北京大学', '清华大学', '北京航空航天大学']},
        {'上海': ['同济大学', '上海交通大学']},
        {'邢台': ['邢台学院', '邢台一中']},
        {'泸州': ['泸州大学']},
      ]
      let option = {
        series: [
          {
            type: "map",
            map: "china",
            roam: false,
            zoom: 1.23,
            center: [105, 36],
            // geoIndex: 1,
            // aspectScale: 0.75, //长宽比
            showLegendSymbol: false, // 存在legend时显示
            label: {
              normal: {
                show: false
              },
              emphasis: {
                show: false
              }
            },
            itemStyle: {
              normal: {
                areaColor: "#fbfdff",
                borderColor: "#389dff",
                borderWidth: 0.5
              },
              emphasis: {
                areaColor: "#fbfdff",
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowBlur: 5,
                borderWidth: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }
          },
          {
            name: "",
            type: "scatter",
            coordinateSystem: "geo",
            data: dataValue,
            // symbolSize: function(val) {
            //   return val[2] / 10;
            // },
            symbol: "circle",
            symbolSize: 10,
            hoverSymbolSize: 15,
            tooltip: {
              formatter(value) {
                const cityName = value.data.name;
                let schoolInfo = '';
                citySchoolMap.forEach(obj => {
                  if (obj[cityName]) {
                    schoolInfo = obj[cityName].join("<br/>");
                  }
                });
                const resultDiv = '<div class="custom-tooltip">' +
                    cityName + '市' + "<br/>" +
                    '<span class="highlight">' + schoolInfo + '</span>' +
                    '</div>';
                // return cityName +'市'+ "<br/>"+  '<span class="highlight">' + schoolInfo + '</span>';
                return resultDiv
              },
              show: true,
              backgroundColor: '#f4f6f8',
              textStyle: {
                color: "#333",
                fontSize: 12
              },
              borderColor: '#333',
              padding: 7
            },
            encode: {
              value: 2
            },
            label: {
              formatter: "{b}",
              position: "right",
              show: true,  //展示城市名称
              fontWeight: 'bolder'
            },
            itemStyle: {
              color: "#1907f8"
            },
            emphasis: {
              label: {
                show: false
              }
            }
          }
        ]
      };
      this.myEchart.setOption(option);
    },
    GetQueryString(name) {
      if (name.indexOf("#") != -1) {
        const params = name.split("#")[0];
        return params;
      }
    },

  }
}
</script>

<style scoped lang="scss">
.left-style {
  display: flex;
  justify-content: flex-start;
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 0.747rem;
}

ul {
  list-style: none;
}

//

.logo {


  background-color: #32abfc;
  margin-left: 0.467rem;
  width: 0.6rem;
  height: 0.6rem;
  margin-top: -0.4rem;

  .img {
    width: 100%;
    border: 0.013rem solid red;
    height: 100%;

  }

  img {
    padding: 0;
    margin: 0;
  }
}

.titleWord {
  font-weight: 550;
  mix-blend-mode: normal;
  text-align: center;
  color: rgba(0, 0, 0, 1);
  font-family: "Microsoft YaHei", serif;
  font-size: 0.187rem;
  display: flex;
  justify-content: flex-start;

  li {
    cursor: pointer;
    margin-right: 0.2rem;
  }
}

.right-style {
  margin-top: -0.533rem;

  .download {
    padding-right: 0.133rem;
    color: #909090;
  }
}

.loginBtn-style {
  color: black;
  font-size: 0.187rem;
  mix-blend-mode: normal;
  border-radius: 0.32rem;
  background: linear-gradient(
          132.63deg,
          rgba(17, 93, 245, 1) 2.35%,
          rgba(0, 117, 252, 1) 94.2%
  );
  padding: 0.2rem;
}

.register-style {
  font-size: 0.187rem;
  mix-blend-mode: normal;
  color: rgba(144, 144, 144, 1);
  font-family: PingFang SC, serif;
  font-weight: 600;
  margin-right: 0.067rem;
  padding: 0.2rem;
}

//轮播图
.banner {
  position: relative;
  //width: 1200px;
  width: 100%;
  height: 8rem;
  //border:1px solid red;
  margin: 0 auto;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 0.24rem;
  opacity: 0.75;
  line-height: 4rem;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.banner-item {
  img {
    width: 100%;
    height: 100%;
  }
}

.title-word-style {
  mix-blend-mode: normal;
  color: rgba(0, 0, 0, 1);
  font-family: PingFang SC, serif;
  font-weight: 600;
  font-size: 0.427rem;
}

.content-style {
  mix-blend-mode: normal;
  color: rgba(98, 98, 98, 1);
  font-family: PingFang SC, serif;
  font-weight: 600;
  font-size: 0.16rem;
}

.banner-all {
  z-index: 9;
  position: absolute;
  top: 25%;
  left: 10%;
  display: flex;
  flex-direction: column;
  width: 4rem;
}

//实施算网融合发展行动
.action {
  position: relative;
  height: 11.573rem;
  //border: 1px solid red;
  //background-color: blue;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  img {
    width: 80%;
    height: 50%;
  }
}

.clearFloat {
  clear: both;
}

.top-right {
  position: absolute;
  right: 0;
  top: 0;
}

.top-left {
  position: absolute;
  left: 0;
  bottom: 0;
}

.action-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  margin: 0 auto;
  width: 80%;
  height: 100%;
}

.content-title-before {
  mix-blend-mode: normal;
  border: 0.04rem solid rgba(255, 255, 255, 1);
  text-align: center;
  color: rgba(0, 0, 0, 1);
  font-family: PingFang SC, serif;
  font-weight: 600;
  font-size: 0.4rem;
}

.content-title-last {
  mix-blend-mode: normal;
  border: 0.04rem solid rgba(255, 255, 255, 1);
  text-align: center;
  font-family: PingFang SC, serif;
  font-weight: 600;
  font-size: 0.4rem;
  color: rgba(17, 93, 245, 1);
}

.num {
  //margin-top: px;
  width: 35%;

  height: 6.667rem;
}

.pic-right {
  width: 40%;
  height: 100%;
  //background-color: skyblue;
  display: flex;
  flex-direction: column;

  img {
    margin: 0.2rem 0;
    margin-left: 2rem;
    width: 2.667rem;

    height: 2.667rem;
  }
}

.content-main {
  display: flex;
  justify-content: center;
  align-items: center;

  ul {
    li {
      margin-bottom: 0.2rem;
      cursor: default;
    }
  }
}

.content-item {
  margin-bottom: 0.2rem;
  width: 4.587rem;
  height: 1.253rem;
  mix-blend-mode: normal;
  border-radius: 0rem 0.107rem 0.107rem 0.107rem;
  border: 0.013rem solid rgba(239, 239, 239, 1);
  box-sizing: border-box;
  position: relative;
}

.little-item {
  width: 4.587rem;
  height: 1.253rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 0.267rem;
}

.h-word {
  mix-blend-mode: normal;
  color: rgba(0, 0, 0, 1);
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 0.213rem;
}

.c-word {
  mix-blend-mode: normal;
  color: rgba(138, 138, 138, 1);
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 0.16rem;
  margin-top: 0.067rem;
}

.num-icon {
  position: absolute;
  top: 0.04rem;
  left: -0.24rem;
  width: 30%;
  height: 1.067rem;
}

.icon-footer {
  width: 0.64rem;
  height: 0.64rem;
}

.content-footer-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0.733rem;

  img {
    width: 1.12rem;
    height: 0.64rem;
    margin: 0.2rem 0;
  }
}

.footer-title {
  mix-blend-mode: normal;
  color: rgba(0, 0, 0, 1);
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 0.28rem;
}

.footer-content {
  margin-top: 5px;
  mix-blend-mode: normal;
  text-align: center;
  color: rgba(138, 138, 138, 1);
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 0.187rem;
  width: 1.8rem;
}

//核心业务
.business {
  width: 100%;
  height: 10.893rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.business-title {
  mix-blend-mode: normal;
  text-align: center;
  color: rgba(0, 0, 0, 1);
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 0.4rem;
}

.business-content {
  mix-blend-mode: normal;
  text-align: center;
  color: rgba(138, 138, 138, 1);
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 0.213rem;
}

.business-icon-footer {
}

.business-pic {
  width: 13.867rem;
  height: 4.8rem;
}

//高校网络
.shcool {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.shcool-main {
  position: relative;
  width: 16.16rem;
  height: 8.213rem;

  //border:1px solid red;
  img {
    width: 100%;
    height: 100%;
  }
}

.left-content {
  position: absolute;
  top: 0.667rem;
  left: 0.333rem;
  width: 6.667rem;
  height: 6.667rem;
}

.c-one {
  position: absolute;
  top: 0.667rem;
  left: 0;
  width: 2.04rem;
  height: 2.04rem;
  border-radius: 50%;
  background: rgba(255, 169, 0, 0.74);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.school-title {
  mix-blend-mode: normal;
  text-align: center;
  color: rgba(161, 67, 0, 1);
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 0.213rem;
}

.school-school {
  mix-blend-mode: normal;
  text-align: center;
  color: rgba(161, 67, 0, 1);
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 0.48rem;
}

.c-two {
  position: absolute;
  top: 0;
  left: 1.6rem;
  width: 1.56rem;
  height: 1.56rem;
  border-radius: 50%;
  background: rgba(0, 119, 255, 0.74);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.school-title-two {
  font-size: 0.213rem;
}

.c-thr {
  position: absolute;
  top: 1.24rem;
  left: 1.853rem;
  width: 1.173rem;
  height: 1.173rem;
  border-radius: 50%;
  background: rgba(0, 155, 24, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.top-school {
  position: relative;
}

.tip-word {
  mix-blend-mode: normal;
  color: rgba(65, 70, 79, 1);
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 0.24rem;
}

.shcool-dscribtion {
  mix-blend-mode: normal;
  color: rgba(0, 0, 0, 1);
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 0.187rem;
}

.main-content {
  position: absolute;
  top: 3.333rem;
}

.main-title {
  mix-blend-mode: normal;
  color: rgba(65, 70, 79, 1);
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 0.24rem;
}

//欢迎体验
.like {
  position: relative;
  width: 100%;
  height: 3.2rem;

  img {
    width: 100%;
    height: 100%;
  }
}

.footer {
  width: 100%;
  height: 3.333rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.left-box {
  width: 50%;
  //border: 1px solid red;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-box {
  width: 50%;
  //border: 1px solid red;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-main {
  mix-blend-mode: normal;
  color: rgba(0, 0, 0, 1);
  font-family: PingFang SC;
  font-size: 0.187rem;
}

.logo-footer {
  width: 2.667rem;
  height: 0.667rem;

  .img {
    width: 1.067rem;
    height: 1.067rem;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.qr-code {
  img {
    width: 100%;
    height: 100%;
  }

  width: 1.853rem;
  height: 1.853rem;
  //border:1px solid red;
}

.qr-content {
  mix-blend-mode: normal;
  color: rgba(24, 24, 24, 1);
  font-family: PingFang SC, serif;
  font-weight: 600;
  font-size: 0.187rem;
}

.qr-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.logo {
  background-color: #32abfc;

  img {
    width: 100%;
    height: 100%;
  }
}

//ecahrt
.leftCard {
  width: 100%;
  height: 100%;

  #chinaMap {
    width: 100%;
    height: 100vh;
  }
}

.wrapper {
  width: 100%;
  border: 0.013rem solid red;
  height: 6.667rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.map-container {
  width: 16rem;
  height: 6.667rem;
  border: 0.013rem solid red;
}

.highlight {
  font-size: 1.667rem;
}

.custom-tooltip {
  width: 6.667rem;
  height: 6.667rem;
}

.pic-word {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hiTitle {
  font-size: 0.373rem;
  padding: 0.16rem;
  opacity: 0.8;
  mix-blend-mode: normal;

  color: #f35626;
  font-weight: 700;
  background: coral;
  background-image: -webkit-linear-gradient(
          45deg,
          rgba(17, 239, 222, 1),
          rgba(2, 117, 250, 1)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: hue 6s infinite linear;
}

.goLogin-style {
  &:hover {
    background-color: #aad1fd;
    color: white;
  }

  font-size: 0.32rem;
  cursor: pointer;
  color: #32abfc;
  width: 2.667rem;
  height: 0.56rem;
  opacity: 0.8;
  mix-blend-mode: normal;
  border-radius: 0.04rem;
  border: 0.013rem solid rgba(54, 147, 251, 1);
  box-sizing: border-box;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.news-block {
  padding: 0.027rem;
  width: 4.667rem;
  height: 4.267rem;
  border: 0.013rem solid #4191e0;
  position: absolute;
  top: 3.733rem;
  right: 0;
}

.pic-news {
  width: 2.667rem;
  height: 2.667rem;
}

.title-news {
  mix-blend-mode: normal;
  color: rgba(1, 119, 248, 1);
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 0.28rem;
}

.news-content {
  mix-blend-mode: normal;
  text-align: justify;
  color: rgba(0, 0, 0, 1);
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 0.187rem;
}

.ckl {
  img {
    width: 100%;
    height: 100%;
  }

  div {
    margin-right: 0.2rem;
  }
}

.footer-top-style {
  width: 16rem;
  height: 1.333rem;
  margin: 0 auto;
  margin-top: 0.133rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.item-footer-top {
  img {
    width: 0.427rem;
    height: 0.427rem;
  }

  display: flex;
  justify-content: center;
  align-items: center;
}

.resource {
  mix-blend-mode: normal;
  color: rgba(0, 0, 0, 1);
  font-family: PingFang SC;
  font-size: 0.267rem;
  margin-left: 0.133rem;
}

.friends {
  width: 16rem;
  height: 2.667rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.2rem;
}

.logo-item-s {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  img {
    margin-right: 0.267rem;
    //border:1px solid red;
    width: 1.333rem;
  }

  width: 100%;
  height: 6rem;
}

.intrudceMessage {
  width: 16rem;
  height: 0.667rem;
  margin: 0 auto;
  margin-top: -0.333rem;
  font-size: 0.173rem;
  margin-bottom: 0.2rem;
}

::v-deep .el-carousel__container {
  height: 4.8rem;
}
</style>
