<template>
  <div class="all-box">

    <div style="width: 100%;">
      <ul class="host-grid product-mobile"
          style="height: auto!important;display: flex;flex-wrap: wrap;justify-content:flex-start;align-items: center;padding-left:2%;padding-right: 0">
        <li style="margin: 5px 7px;" class="all-li mp-flex host-container chaosuan-ul "
            v-for="(item,index) in productList"
            :key="index">
          <div class="mp-text-normal host-card avail"
               style="height: 100%;z-index: 1"><!---->
            <div class="host-card-container" style="position: relative;">
              <div class="header"><h3 style="margin-top: 0" class="mp-h6 title">
                {{ item.name }}</h3>
                <div class="ali-description" v-if="item.description==='product_sync'"
                     style="font-size: 12px;color: #777684">
                  安全可靠、弹性可伸缩的云计算服务, 提供一站式管理容器应用服务
                </div>
                <div style="z-index: 99" v-if="false">
                  <el-select style="border:1px solid red;z-index: 99" v-model="value" placeholder="请选择">
                    <el-option
                        v-for="item in manufacturerList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </div>

                <ul class="ali-ul" v-if="item.description==='product_sync'" style="margin-top: 10px">

                  <li><span class="ali-title"> 业务类别:</span>云业务</li>
                  <li><span class="ali-title"> 产品类型:</span>agency</li>
                </ul>
                <!--                <div v-if="item.description==='product_sync'" class="ali-bg">-->
                <div v-if="false" class="ali-bg">
                  <img style="width: 100%;height: 100%;" src="../images/alilogo.svg" alt="">
                </div>
                <div v-if="item.description!=='product_sync'" class="mp-text-h6 price">
                  <!--                  <span class="color-dark symbol">￥</span><span-->
                  <!--                  class="mp-h3 color-dark value">{{ item.price }}</span><span-->
                  <!--                  class="color-dark symbol heshi">/核时</span>-->
                  <span class="price" v-if="item.price==0||item.price==null">- </span>
                  <span class="price" v-else>{{
                      item.ptype == "14" ? new Decimal(item.discount_price ? item.discount_price : item.price).div(100).toFixed(2) : (item.discount_price ? item.discount_price.toFixed(2) : item.price.toFixed(2))
                    }}</span>

                  <span class="price-other">元/{{ computedUnit(item.unit) }}</span>
                </div>
                <div class="mp-flex row-tag">
                  <!--                <span v-if="item.description!=='product_sync'" class="tag ant-tag avail-tag avail"-->
                  <!--                      style="width: 120px;height: 30px;font-size: 12px">-->
                  <!--                  <span-->
                  <!--                    class="mp-text-normal value">1</span> 张可用 </span>-->
                  <!---->
                  <svg style="height:10px" aria-hidden="true" class="mp-svg-icon clickable">
                    <use xlink:href="#mp-host-network-normal"></use>
                  </svg><!----></div>
              </div>
              <div v-if="item.description!=='product_sync'" class="flex items-center mb-1.5 mt-4 text-md">
                <ul class="item-ul">
                  <li>
                    <img src="../img/便捷_方便.svg" alt="">
                    区域 1 区
                  </li>
                  <li v-show="judgeIsHas('高速网卡',item.spec_note)">
                    <img src="../img/科技(1).svg" alt="">
                    {{ getPeizhiValue('高速网卡', item.spec_note) }}
                  </li>
                  <li v-show="judgeIsHas('CPU',item.spec_note)">
                    <img src="../img/AI_智能.svg" alt="">
                    {{ getPeizhiValue('CPU', item.spec_note) }}
                  </li>
                  <li v-show="judgeIsHas('存储',item.spec_note)">
                    <img src="../img/稳定.svg" alt="">
                    {{ getPeizhiValue('存储', item.spec_note) }}
                  </li>
                  <li v-show="judgeIsHas('内存',item.spec_note)">
                    <img src="../img/研发_代码.svg" alt="">
                    {{ getPeizhiValue('内存', item.spec_note) }}
                  </li>
                </ul>

              </div>

            </div>
            <div
                style="z-index:9;display: flex;justify-content: center;position:absolute;transform:translate(-50%,-50%);left:50%;top:92%;align-items: center;">
              <el-button type="primary" @click="addShoppingCart"
                         style="width: 120px;height: 35px;border-radius: 15px">
                <span>立即使用</span>
              </el-button>
              <el-button type="primary"
                         style="width: 60px;height: 35px;padding: 10px;border-radius: 15px"
                         @click="addShoppingCart(item)"
                         v-show="flagCommon">
                <span>购买</span>
              </el-button>
            </div>
          </div>
        </li>
      </ul>
    </div>


    <div v-if="false" style="width: 100%;height: 100vh;">
      <transition name="el-zoom-in-center">
        <div v-show="show2" class="transition-box"
             style="display: flex;justify-content: center;flex-wrap: wrap;width: 100%;">
          <div class="item-main" v-for="(item, index) in productList" :key="index" style="margin: 0 15px 2% 2%">
            <!--      顶部title-->
            <div class="top-title"><span class="title-content">{{ item.name }}</span></div>
            <!--中部内容区-->
            <div class="main-content">
              <ul v-for="(configItem, index) in JSON.parse(item.spec_note)" :key="configItem.id" class="ul-style">
                <el-tooltip class="item" effect="light" :content="configItem.value" placement="bottom">
                  <li v-show="configItem.configName" style="cursor: pointer;overflow: hidden!important;">
                    {{ configItem.configName }}：
                    {{ configItem.value }}
                  </li>

                </el-tooltip>

                <!-- <li>同构/异构： {{ item.itemStructure }}</li>
                <li>加速卡： {{ item.itemHighSpeedCard }}</li>
                <li>内存： {{ item.itemHighMemory }}</li>
                <li>高速网卡： {{ item.itemHighSpeedCard }}</li> -->
              </ul>
            </div>
            <!--      分割线-->
            <div class="line"></div>
            <!--     标签-->
            <!-- <div class="footer-tag" v-show="item.tags.length > 0" style="width: 100%;"
                v-for="(tag, index) in item.tags" :key="index">
                <ul  style="width: 100%;bor">
                    <li class="tag-item-style">{{ tag }}</li>
                </ul>
            </div> -->

            <div class="footer-tag" style="width: 100%;">
              <ul
                  style="margin-top: 20px;display: flex;justify-content: space-around;align-items: center;width: 100%;font-size: 14px;padding: 0!important;">
                <li class="tag-item-style">超算集群</li>
                <li class="tag-item-style"> 计算密集型</li>
                <li class="tag-item-style"> x86架构</li>
              </ul>
            </div>

            <!--      footer-->
            <div class="footer-btn">
              <div class="price-style">

                <div style="display: flex;align-items: center;justify-content: center">
                  <span class="price" v-if="item.price==0||item.price==null">- </span>
                  <span class="price" v-else>{{
                      item.ptype == "14" ? new Decimal(item.discount_price ? item.discount_price : item.price).div(100).toFixed(2) : (item.discount_price ? item.discount_price.toFixed(2) : item.price.toFixed(2))
                    }}</span>

                  <span class="price-other">元/核时</span>
                </div>
                <div style="display: flex;justify-content: center;align-items: center">
                  <span class="price" v-if="item.price&&item.discount_price "
                        style="text-decoration: line-through;font-size: 18px"><span
                      class="beforePrice">原：</span>{{
                      item.ptype == "14" ? new Decimal(item.price).div(100).toFixed(2) : item.price.toFixed(2)
                    }} <span class="price-other">元/核时</span> </span>
                </div>

              </div>
              <div style="width: 175px;display: flex;justify-content: space-around;align-items: center">
                <div class="btn-style-use jdc-index-btn arr-white" @click="addShoppingCart()">立即使用</div>
                <div class="btn-style-buy" @click="addShoppingCart()" v-show="flagCommon">购买</div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>


  </div>
</template>

<script>

import {mapState} from "vuex";

import '../index.css'
import {goUrl} from "@/utils/settings";

export default {
  name: "productItem",
  props: ['productList', 'flagCommon'],
  components: { },
  data() {
    return {
      manufacturerList: [{
        value: '阿里云',
        label: '阿里云'
      }, {
        value: '超算',
        label: '超算'
      }, {
        value: '定制服务',
        label: '定制服务'
      }, {
        value: '互联网接入',
        label: '互联网接入'
      }, {
        value: '集团项目',
        label: '集团项目'
      }, {
        value: '智算',
        label: '智算'
      }],
      value: '',
      products: [
        {
          Supercomputing_name: 'CPU节点/1.5T X86-Intel®6258R',
          CPU: '2 颗 Intel® 6258R CPU，单颗处理器 28 核、主频 2.7GHz',
          memory: ' 1.5T DDR4',
          netcard: '2 * 万兆网口; 100Gb/s 高速网卡',
          storage: '2 * 480G SSD 硬盘;2 * 4TB SATA硬盘',
          price: '0.50'
        },
        {
          Supercomputing_name: 'CPU节点/192G X86-Intel®6258R',
          CPU: '2颗Intel® 6258R CPU，单颗处理器28核、主频 2.7GHz',
          memory: '192G DDR4',
          netcard: '2 * 万兆网口;100Gb/s 高速网卡',
          storage: '960G SSD硬盘',
          price: '0.32'
        },
        {
          Supercomputing_name: 'CPU节点/384G X86-Intel®6258R',
          CPU: '2颗Intel® 6258R CPU，单颗处理器28核、主频 2.7GHz',
          memory: '384G DDR4',
          netcard: '2 * 万兆网口;100Gb/s 高速网卡',
          storage: '960G SSD 硬盘',
          price: '0.35'
        },
      ],

      dialogDetailTableVisible: false,
      deviceDetailData: [],
      configNameMap: {
        'system_disk': '系统磁盘',
        'card_type': '算卡类型',
        'device_brand': '设备品牌',
        'net_card2': '网卡2',
        'cpu': 'cpu',
        'net_card1': '网卡1',
        'device_type': '设备类型',
        "resource_type": '资源类型',
        "data_disk": "数据盘",
        "card_interface": "算卡接口",
        "memory": "内存"
      },
      simpleItem: {},
      buyProductConut: 1,
      lastTimeDate: '',
      canPurchase: false,
      deviceListVisble: false,
      leaseTime: '',
      dialogTableVisible: false,
      show2: false,
      dialogVisible: false,
      item_product: {},
      dialogVisibleItemProduct: false,
      currentSelection: [],
      current_providerid: "",
      current_productid: '',
      noDataDialogData: {
        showEmptyDataMessage: false,
        messageWords: '该产品设备暂无数据，请稍后重试！',
        propsKey: 'update-noDataDialogData-showEmptyDataMessage'
      },
      dateMap: {
        '一个月': 30,
        '三个月': 90,
        '六个月': 180,
        '一年': 365,
        '两年': 730,
        '三年': 1095,
      },
      sale_days: null,
      new_form: {
        device_type: '',
        card_type: '',
      },

    }
  },
  mounted() {
    this.show2 = true
    this.userId = sessionStorage.getItem('userId')
    // this.initData()
    // this.judgeIsHas()
  },

  beforeDestroy() {
    this.show2 = false
  },
  activated() {
    this.show2 = true
  },
  deactivated() {
    this.show2 = false
  },
  methods: {
    computedUnit(unit) {
      switch (unit) {
        case 'year':
          return '年';
        case 'quarter':
          return '季度';
        case 'month':
          return '月';
        case 'week':
          return '周';
        case 'day':
          return '天';
        case 'hour':
          return '核时';
        default:
          return '-'; // 如果没有匹配的情况，可以返回一个空字符串或其他默认值
      }
    },

    getPeizhiValue(str, strAll) {
      let resultStr = ''
      let result = JSON.parse(strAll)
      let valueArr = Object.values(result)
      for (let i = 0; i < valueArr.length; i++) {
        if (valueArr[i].configName === str) {
          resultStr = valueArr[i].value
          return resultStr
        }
      }
      return resultStr
    },

    judgeIsHas(str, strAll) { //
      let falg = false
      let result = JSON.parse(strAll)
      for (let i = 0; i < result.length; i++) {
        if (result[i].configName === str) {
          falg = true
          return falg
        }
      }
      return falg
    },
    initData() {
      //productList

      for (let i = 0; i < this.productList.length; i++) {
        let item = {}
      }
    },
    showDetailTable() {
      this.dialogDetailTableVisible = true
    },
    //新版产品化详情请求
    async deviceDetail(id) {
      await reqDeviceDetail({server_id: id}).then(res => {
        if (res.status) {
          console.log("详情res是：", res)
          this.deviceDetailData = res.data
          this.deviceDetailData.map(item => {
            if (item.title == 'device_type') {
              this.new_form.device_type = item.parameter
            }
            if (item.title == 'card_type') {
              this.new_form.card_type = item.parameter
            }
          })
          // this.dialogTableVisible = true
        } else {
          this.$message.error('当前设备详情有误，请稍后重试！')
        }
      })
    },
    handleUpdate(value) {
      this.noDataDialogData.showEmptyDataMessage = value
    },
    async handleDateChange(end_date) {
      this.deviceListVisble = true
      // let playod = {
      //   providerid: this.current_providerid,
      //   productid: this.current_productid,
      //   status: 0,
      //   end_date:end_date
      // }
      // await this.$store.dispatch('getproductizationProduct', playod)
      this.deviceListVisble = false
      //如果时间选择器的回调请求数据为空

    },
    // 新版立即购买
    nowPurchase() {


      //新产品点击购买
      this.beforePurchase(this.simpleItem)


    },
    //新品判断时间是否超限
    judgeBuyTime(item) {
      //截止日期
      let Last_time = this.productizationProduct[0].end_date
      let Start_time = ''
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      Start_time = `${year}-${month}-${day}`;
      // 计算二者相差天数
      const startDate = new Date(Start_time);
      const endDate = new Date(Last_time);

// 计算两个日期之间的毫秒数差异
      const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());

// 将毫秒数转换为天数
      this.sale_days = Number(Math.ceil(timeDiff / (1000 * 3600 * 24)) - 1);
    },
    handleSelectionChange(val) {

      this.currentSelection = val;
      if (val.length > 0) {
        this.canPurchase = true
      } else {
        this.canPurchase = false
      }
    },
    tradeMarkFather(trademark) {
      console.log('我是父组件')
      this.dialogVisibleItemProduct = false
    },
    async addShoppingCart() {
window.open(goUrl)
    },
    close() {
      // this.isShow = false
      // this.$refs.payForm.resetFields();
      this.dialogVisibleItemProduct = false;
    },

    //原始购买
    beforePurchase(item) {

      let pamars = {
        userid: this.userId,
        productid: this.current_productid,
        quantity: this.buyProductConut,
        providerid: item.providerid,
        list_price: item.price,
        amount: item.price,
      };
      if (item.discount_price && item.discount) {
        pamars.discount = item.discount;
        pamars.amount = item.discount_price;
      }

      //锁定产品
      //获取今天时间
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');

      const formattedDate = `${year}-${month}-${day}`;

      let lockPalyod = {
        lease_start_time: formattedDate,
      }

      const dateStr = formattedDate;
      const date = new Date(dateStr);

// 使用 setDate() 方法设置日期为给定日期加上 n 天后的日期
      const n = this.dateMap[this.lastTimeDate];
      date.setDate(date.getDate() + n);

// 将日期对象转换为字符串格式
      const newDateStr = date.toISOString().slice(0, 10);
      lockPalyod.lease_end_time = newDateStr
      lockPalyod.productid = this.current_productid
      lockPalyod.count = this.buyProductConut * 1
      console.log("lockPalyod是", lockPalyod)
      reqLockProduct(lockPalyod).then(result => {
        if (result.status) {
          addOrderAPI(pamars).then((res) => {
            if (res.status == true) {
              this.$message({
                message: "成功生成订单",
                type: "success",
              });
              //保存订单id
              let lockPalyod = {
                count: this.buyProductConut,
                order_id: res.bz_id,
                productid: this.current_productid
              }
              // this.currentSelection.forEach(item => {
              //   lockPalyod.server_ids.push(item.id)
              // })
              reqSavceSuppilerProductOrderId(lockPalyod).then(result => {
                if (result.status) {
                  this.$router.push({name: "orderDetil", query: {id: res.bz_id}});   // orderDetil点击去这个页面
                } else {
                  this.$message.error('产品锁定失败')
                }
              })
            } else {
              this.isShow = false
              this.$message({
                message: "生成订单失败!",
                type: "error",
              });
              console.log("realseIds", this.productizationProduct)
              let realseIds = {
                productid: this.productizationProduct[0].productid,
                count: this.buyProductConut * 1
              }
              reqReasleProductById(realseIds).then(res => {

              })
            }
          });
        } else {
          this.$message.error(result.msg)
        }
      })


    },
  },
  computed: {
    Decimal() {
      return Decimal
    },
    ...mapState({
      productizationProduct: state => state.product.productizationProduct
    })
  }
}
</script>

<style scoped lang="scss">
.avail {
  color: black;
}

.heshi {
  color: blue;
  font-weight: 550;
  margin-left: 15px;
}

.title-style-new {
  font-weight: bold;
  margin-right: 3px;
}

::v-deep .el-radio-button .el-radio-button__inner {
  font-size: 14px;
}

.all-box {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  //padding: 0 2%;
  width: 100%;

}

.item-main {
  background-color: white;
  width: 360px;
  height: 425px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 15px;
  margin-bottom: 15px;
}

.top-title {
  width: 100%;
  height: 58px;

  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #2468f2ff 0%, #528bffff 100%);
}

.title-content {
  display: inline-block;
  width: 80%;
  height: 100%;
  //border-top-left-radius: .3125rem;
  //border-top-right-radius: .3125rem;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.main-content {
  width: 350px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ul {
    list-style: none;

    li {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      box-sizing: border-box;
      margin: 15px 0;
      font-size: 18px;
    }
  }
}

.line {
  width: 90%;
  height: 1px;
  background-color: #020affff;
}

.footer-tag {
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ul {
    // display: flex;
    //justify-content: flex-start;
    //align-items: flex-start;
    list-style: none;
    // flex-wrap: wrap;
    li {
      color: #4e79fa;
      // padding: 4px;
      background-color: #ebfafc;
      //margin: 0 5px;
    }
  }
}

.footer-btn {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  height: 35px;
}

.price-style {
  margin: 0;
  padding: 0;

  span {
    padding: 0;
    margin: 0;
  }
}

.btn-style-use {
  position: relative;
  width: 120px;
  color: #fff;
  background-color: #0007d4;
  border-color: #0007d4;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  //&:hover::after{
  //  content: "";
  //  position: absolute;
  //  top: 50%;
  //  right: 0;
  //  width: 6px;
  //  height: 8px;
  //  margin-top: -4px;
  //  background: url("data:image/svg+xml,%3Csvg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.375.625L4.75 4 1.375 7.375' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  //  -webkit-transition: all .25s ease;
  //  -moz-transition: all .25s ease;
  //  transition: all .25s ease;
  //}
}

.btn-style-buy {
  color: #fff;
  background-color: #0007d4;
  border-color: #0007d4;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
}

.price-other {
  font-size: 20px;
  font-weight: 700;
  color: #5f6a75;
  margin: 0;
  padding: 0;
}

.price {
  color: #020aff;
  font-size: 28px;
  font-weight: 600;
  margin: 0;
  padding: 0;

}

.tag-item-style {

  width: 80px;
  //border: 1px solid rebeccapurple;
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 100%;
  //width: 80px;
  //padding: 0 35px!important;
}

.purchase-footer {
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.right-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.beforePrice {
  color: #0b0b0b;
  text-decoration: none !important;
}

.ul-style {
  margin: 0;
  padding: 0;
  padding-left: 25px;
}


</style>
<style>
.deviceListStyle {
  min-width: 700px !important;
}

@media screen and (max-width: 760px) {
  .mobilStyle {
    display: flex;
    flex-wrap: wrap;

    .el-radio-button {
      margin-right: 15px;
      border-left: 1px solid #DCDFE6;
      border-radius: 4px;

    }

    .el-radio-button--mini .el-radio-button__inner {
      font-size: 14px;

    }

    .el-button--medium {
      font-size: 12px !important;
    }
  }

  .contentStyle {
    width: 88vw !important;
    height: 350px;
    margin-top: 10vh !important;
  }

  .deviceListStyle {
    width: 100vw !important;
    height: calc(100vh - 200px);
    margin-top: 16vh !important;
    overflow-y: auto;

  }

  .purchase-footer {
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    font-size: 16px;
  }

  .purchaseNumber {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 15px 0;
  }

  .el-radio-button--mini .el-radio-button__inner {
    font-size: 18px;
  }

  .main-table {
    height: 150px !important;
  }

  .productPurchase {
    height: 500px;
    width: 90vw !important;
    font-size: 14px !important;

    .el-form-item__content div {
      font-size: 14px !important;
    }

    .el-button {
      font-size: 14px !important;
    }
  }

}


.jdc-index-btn:hover {
  color: #fff;
  padding: 0 25px 0 15px;
}

.arr-white:hover {
  color: #fff;
}

.jdc-index-btn {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  padding: 0 20px;
  min-width: 104px;
  height: 36px;
  line-height: 32px;
  text-align: center;
  font-size: 14px;
  color: #fff;
}

.arr-white {
  position: relative;
  color: #fff;
  padding-right: 15px;
  -webkit-transition: all .25s ease;
  -moz-transition: all .25s ease;
  transition: all .25s ease;
}

.jdc-index-btn:hover::after {
  opacity: 1;
}

.arr-white:hover::after {
  -webkit-transform: translate(4px);
  -ms-transform: translate(4px);
  transform: translate(4px);
}

.jdc-index-btn::after {
  opacity: 0;
  right: 16px;
}

.arr-white::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 15%;
  width: 6px;
  height: 8px;
  margin-top: -4px;
  background: url("data:image/svg+xml,%3Csvg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.375.625L4.75 4 1.375 7.375' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  -webkit-transition: all .25s ease;
  -moz-transition: all .25s ease;
  transition: all .25s ease;
}

.jdc-index-btn:hover {
  color: #fff;
}

.arr-white:hover {
  color: #fff;
}

a:hover {
  color: #E1251B;
}

.jdc-index-btn {
  line-height: 32px;
  text-align: center;
  font-size: 14px;
  color: #fff;
}


.chaosuan-ul {
  &:hover .price, &:hover .price-other {
    color: white !important;;
  }

  &:hover li {
    color: white !important;


    .price {
      color: white !important;;
    }

    #heshi {
      color: white !important;
    }
  }
}

.host-grid {

  & > li {
    width: 274px;
    height: 400px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
}

.item-ul {
  width: 100% !important;

  padding: 5px 20px;

  li {
    margin: 5px 0;
    width: 100%;
    color: #777684;

    img {
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }

    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

@media screen and (max-width: 760px) {
  .host-grid {
    justify-content: center !important;
  }
}

.ali-ul {
  color: #4c4948;
  height: 300px;

  li {
    margin-bottom: 10px;
    list-style: none;


  }
}

.ali-bg {
  padding: 0 35px;
  position: absolute;
  top: 100px;
  right: 0;
  width: 100%;
  height: 300px;
  z-index: 0;

}

.all-li {
  &:hover {
    .ali-description {
      color: white !important;
    }

    .item-ul {
      li {
        img {
          filter: brightness(0) invert(1);
        }

      }
    }

    .ali-bg {
      filter: brightness(0) invert(1);
    }
  }
}

</style>
