import demoTwo from "@/pages/demoTwo/index.vue";
import demoOne from "@/pages/demoOne/index.vue";
import HelloWorld from "@/components/HelloWorld.vue";
import Home from "@/pages/home/index.vue";
import HomeIndex from "@/pages/home/homeIndex.vue";
import SuanShop from "@/pages/home/suanShop/index.vue";
import About  from '@/pages/home/about/index.vue'
import Dmx from "@/pages/home/dmx/index.vue";
import Zs from "@/pages/home/zs/index.vue";
import Cxy from "@/pages/home/cxy/index.vue";
export default [
    {
        path: '/home',
        name: 'home',
        component: Home,
        children: [
            {
                path:'index',
                component: HomeIndex
            },
            {
                path:'suanShop',
                component: SuanShop
            },
            {
                path:'about',
                component: About
            },
            {
                path:'dmx',
                component: Dmx
            },
            {
                path:'zs',
                component: Zs
            },
            {
                path:'cxy',
                component: Cxy
            },
        ]
    },
    {
        path:'/demoTwo',
        name: 'demoTwo',
        component: demoTwo
    },
    {
        path:'/hello',
        name:'hello',
        component: HelloWorld
    }
    ,

{
    path:'/',
    redirect:'/home/index',
}
]