<template>
  <div class="market-hosts">
    <div class="on" v-if="product.product_type==='chaousan'" style="width: 100%">
      <product-item class="main-content-product"
                    :productList="productSpuerComputingList"
                    :flagCommon="isCommon"></product-item>
    </div>
    <ul  v-else class="host-grid product-mobile" style="height: auto!important;padding-bottom: 45px">
      <li style="margin-top: 25px" v-if="product.product_type==='chaousan'||product.product_type==='chaousan1'"
          class="mp-flex host-container chaosuan-ul mobile-yun"
          v-for="(item,index) in product.products"
          :key="index">
        <div class="mp-text-normal host-card avail"><!---->
          <div class="host-card-container" style="height: 400px;position: relative">
            <div class="header"><h3 class="mp-h6 title">
              {{ item.Supercomputing_name }}</h3>
              <div v-if="product.product_type!=='chaousan1'" class="mp-text-h6 price"><span
                  class="color-dark symbol">￥</span><span
                  class="mp-h3 color-dark value">{{ item.price }}</span><span>/核时</span></div>
              <div class="mp-flex row-tag">
                <!--                <span class="tag ant-tag avail-tag avail" style="width: 120px;height: 30px;font-size: 12px">-->
                <!--                  <span-->
                <!--                    class="mp-text-normal value">1</span> 1张可用 </span>-->
                <!---->
                <svg style="height:10px" aria-hidden="true" class="mp-svg-icon clickable">
                  <use xlink:href="#mp-host-network-normal"></use>
                </svg><!----></div>
            </div>
            <div class="flex items-center mb-1.5 mt-4 text-md">
              <ul class="item-ul">
                <li>
                  <img src="./img/便捷_方便.svg" alt="">
                  区域 1 区
                </li>
                <li>
                  <img src="./img/科技(1).svg" alt="">
                  {{ item.netcard }}
                </li>
                <li>
                  <img src="./img/AI_智能.svg" alt="">
                  {{ item.CPU }}
                </li>
                <li>
                  <img src="./img/稳定.svg" alt="">
                  {{ item.memory }}
                </li>
                <li>
                  <img src="./img/研发_代码.svg" alt="">
                  {{ item.storage }}
                </li>
              </ul>

            </div>
            <div>
              <div v-if="product.product_type!=='chaousan1'"
                   style="display: flex;justify-content: center;align-items: center;position: absolute;left: 18%;top: 85%">
                <el-button type="primary" @click="goOrigin"
                           style="width: 200px;height: 35px;border-radius: 25px">
                  <span>租用</span>
                </el-button>
              </div>
              <div v-else
                   style="display: flex;justify-content: center;align-items: center;position: absolute;left: 18%;top: 85%">
                <div>
                  <!--                  $router.push(item.path)-->
                  <el-button type="primary" @click="goOrigin(item)"
                             style="width: 200px;height: 35px;border-radius: 25px">
                    <span>购买</span>
                  </el-button>

                </div>
              </div>
            </div>

          </div>
        </div>
      </li>

      <li v-if="product.product_type==='zhisuan'" @click="$router.push('/product/intelligentCalculation')"
          class="mp-flex host-container chaosuan-ul"
          v-for="(item,index) in product.products"
          :key="index" style="height: auto!important;">
        <div class="mp-text-normal host-card avail" style="height: auto"><!---->
          <div class="host-card-container" style="height: auto">
            <div class="header" style="height: 15px!important;  "><h3 class="mp-h6 title">
              {{ item.product_model }}</h3>

            </div>
            <div class="flex items-center mb-1.5 mt-4 text-md" style="height: auto">
              <ul class="item-ul" style="height: auto">
                <li style="cursor: pointer;position: relative">品牌： {{ item.brand }}</li>
                <li style="cursor: pointer;position: relative">IP地址： {{ item.address }}</li>
                <li style="cursor: pointer;position: relative">网卡类型： {{ item.card_interface }}</li>
                <li style="cursor: pointer;position: relative">网卡： {{ item.card_network }}</li>
                <li style="cursor: pointer;position: relative">算卡类型： {{ item.card_type }}</li>
                <li style="cursor: pointer;position: relative">CPU： {{ item.cpu }}</li>
                <li style="cursor: pointer;position: relative">数据盘： {{ item.data_disk }}</li>
                <li style="cursor: pointer;position: relative">数据网卡： {{ item.data_network }}</li>
                <li style="cursor: pointer;position: relative">交付时间： {{ item.leadtime }}</li>
                <li style="cursor: pointer;position: relative">设备所在地： {{ item.location }}</li>
                <li style="cursor: pointer;position: relative">内存： {{ item.memory }}</li>
                <li style="cursor: pointer;position: relative">外网带宽： {{ item.outer_bandwidth }}</li>
                <li style="cursor: pointer;position: relative">资源类型： {{ item.resource_type }}</li>
                <li style="cursor: pointer;position: relative">规模： {{ item.scale }}</li>
                <li style="cursor: pointer;position: relative">外部存储： {{ item.shared_storage }}</li>
                <li style="cursor: pointer;position: relative">系统盘： {{ item.system_disk }}</li>
              </ul>
            </div>
            <div v-if="false" style="display: flex;justify-content: center;align-items: center">
              <el-button type="primary" style="width: 200px;height: 35px;border-radius: 25px"><span>租用</span>
              </el-button>
            </div>
          </div>
        </div>
      </li>

      <!--      <li style="width: 100%;height: auto" v-if="product.product_type==='wangluo'">-->
      <!--        <network-table></network-table>-->
      <!--      </li>-->

    </ul>
    <!--    通用 国产超算跳转-->
    <!--    <div v-if="product.product_type==='chaousan'"-->
    <!--         class="mobile-style-btn" style="display: flex;justify-content: center;align-items: center;padding-top: 35px">-->
    <!--      <myButton :btnText="btnText" style="margin:0  8%;"></myButton>-->
    <!--      <myButton :btnText="btnText1"></myButton>-->
    <!--    </div>-->
    <!--    <div class="mobile-style-btn" v-if="product.product_type==='zhisuan'"-->
    <!--         style="display: flex;justify-content: center;align-items: center;width: 100%;padding-top: 35px">-->
    <!--      <myButton :btnText="btnText2" style="margin:0  8%;"></myButton>-->
    <!--      <myButton :btnText="btnText5"></myButton>-->
    <!--    </div>-->
    <!--    <div class="mobile-style-btn" v-if="product.product_type==='wangluo'"-->
    <!--         style="display: flex;justify-content: center;align-items: center;padding-top: 35px">-->
    <!--      <myButton :btnText="btnText3" style="margin:0  8%;"></myButton>-->
    <!--      <myButton :btnText="btnText4"></myButton>-->
    <!--    </div>-->
    <el-dialog title="联系我们" :visible.sync="dialogVisible" width="500px" :append-to-body="true"
               custom-class="contentStyle" :close-on-click-modal="false">
      <contactUs></contactUs>
      <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible = false"
                           style="background-color: #0007d4">我已知晓</el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>
import './index.css'

import ProductItem from "@/pages/home/suanShop/supercomputingItem/productItem/index.vue";
import {goUrl} from "@/utils/settings";

export default {
  name: "supercomputingItem",
  components: {ProductItem},
  props: ['product'],
  data() {
    return {
      productSpuerComputingList: [
        {
          "id": "ABcI0I4MVuRt6jJ4lxKXM",
          "providerid": "zztJqX-kL8_2sCKKloOQ4",
          "providerpid": "",
          "name": "CPU\u8282\u70b9/1.5T X86-Intel\u00ae6258R",
          "description": "CPU\u8282\u70b9/1.5T X86-Intel\u00ae 6258R",
          "label": null,
          "productgroup": null,
          "ptype": "9",
          "classify": null,
          "state": "",
          "effect_date": "2023-12-13",
          "expire_date": "2024-12-30",
          "salemode": "2",
          "product_code": "",
          "spec_note": "[{\"configName\":\"CPU\",\"value\":\"2 \u9897 Intel\u00ae 6258R CPU\uff0c\u5355\u9897\u5904\u7406\u5668 28 \u6838\u3001\u4e3b\u9891 2.7GHz;\"},{\"configName\":\"\u5185\u5b58\",\"value\":\"1.5T DDR4\"},{\"configName\":\"\u9ad8\u901f\u7f51\u5361\",\"value\":\"2 * \u4e07\u5146\u7f51\u53e3; 100Gb/s \u9ad8\u901f\u7f51\u5361\"},{\"configName\":\"\u5b58\u50a8\",\"value\":\"2 * 480G SSD \u786c\u76d8;2 * 4TB SATA \u786c\u76d8;\"}]",
          "product_area": "\u5c71\u4e1c\u6d4e\u5357",
          "specific_pattern": "",
          "reseller_orgid": "mIWUHBeeDM8mwAFPIQ8pS",
          "del_flg": "0",
          "create_at": "2023-12-13 16:31:28",
          "pay_type": null,
          "resource_type": null,
          "vcpu": null,
          "product_memory": null,
          "hotshow": "1",
          "hotdiscount": null,
          "price": 0.5,
          "unit": "hour",
          "moban": ""
        },
        {
          "id": "DCADjWj-r5lqHGlZqUFYG",
          "providerid": "zztJqX-kL8_2sCKKloOQ4",
          "providerpid": "",
          "name": "CPU\u8282\u70b9/192G X86-Intel\u00ae6258R",
          "description": "CPU\u8282\u70b9/192G X86-Intel\u00ae 6258R",
          "label": null,
          "productgroup": null,
          "ptype": "9",
          "classify": null,
          "state": "",
          "effect_date": "2023-12-13",
          "expire_date": "2024-12-30",
          "salemode": "2",
          "product_code": "",
          "spec_note": "[{\"configName\":\"CPU\",\"value\":\"2\u9897Intel\u00ae 6258R CPU\uff0c\u5355\u9897\u5904\u7406\u566828\u6838\u3001\u4e3b\u9891 2.7GHz;\"},{\"configName\":\"\u5185\u5b58\",\"value\":\"192G DDR4\"},{\"configName\":\"\u9ad8\u901f\u7f51\u5361\",\"value\":\"2 * \u4e07\u5146\u7f51\u53e3;100Gb/s \u9ad8\u901f\u7f51\u5361;\"},{\"configName\":\"\u5b58\u50a8\",\"value\":\"960G SSD\u786c\u76d8\"}]",
          "product_area": "\u5c71\u4e1c\u6d4e\u5357",
          "specific_pattern": "",
          "reseller_orgid": "mIWUHBeeDM8mwAFPIQ8pS",
          "del_flg": "0",
          "create_at": "2023-12-13 16:28:36",
          "pay_type": null,
          "resource_type": null,
          "vcpu": null,
          "product_memory": null,
          "hotshow": "1",
          "hotdiscount": null,
          "price": 0.32,
          "unit": "hour",
          "moban": ""
        },
        {
          "id": "KiKKQh3Q1jNQrsRNxxN_-",
          "providerid": "zztJqX-kL8_2sCKKloOQ4",
          "providerpid": "",
          "name": "CPU\u8282\u70b9/384G X86-Intel\u00ae6258R",
          "description": "CPU\u8282\u70b9/384G X86-Intel\u00ae 6258R",
          "label": null,
          "productgroup": null,
          "ptype": "9",
          "classify": null,
          "state": "",
          "effect_date": "2023-12-13",
          "expire_date": "2024-12-30",
          "salemode": "2",
          "product_code": "",
          "spec_note": "[{\"configName\":\"CPU\",\"value\":\"2\u9897Intel\u00ae 6258R CPU\uff0c\u5355\u9897\u5904\u7406\u566828\u6838\u3001\u4e3b\u9891 2.7GHz;\"},{\"configName\":\"\u5185\u5b58\",\"value\":\"384G DDR4\"},{\"configName\":\"\u9ad8\u901f\u7f51\u5361\",\"value\":\"2 * \u4e07\u5146\u7f51\u53e3;100Gb/s \u9ad8\u901f\u7f51\u5361;\"},{\"configName\":\"\u5b58\u50a8\",\"value\":\"960G SSD \u786c\u76d8\"}]",
          "product_area": "\u5c71\u4e1c\u6d4e\u5357",
          "specific_pattern": "",
          "reseller_orgid": "mIWUHBeeDM8mwAFPIQ8pS",
          "del_flg": "0",
          "create_at": "2023-12-13 16:30:07",
          "pay_type": null,
          "resource_type": null,
          "vcpu": null,
          "product_memory": null,
          "hotshow": "1",
          "hotdiscount": null,
          "price": 0.35,
          "unit": "hour",
          "moban": ""
        },
        {
          "id": "z19jWp-ozxczIwQHYxHm-",
          "providerid": "zztJqX-kL8_2sCKKloOQ4",
          "providerpid": "",
          "name": "CPU\u8282\u70b9/64GX86-Intel- E5-2682V4",
          "description": "CPU\u8282\u70b9/64GX86-Intel- E5-2682V4",
          "label": null,
          "productgroup": null,
          "ptype": "9",
          "classify": null,
          "state": "",
          "effect_date": "2023-12-13",
          "expire_date": "2024-12-30",
          "salemode": "2",
          "product_code": "",
          "spec_note": "[{\"configName\":\"CPU\",\"value\":\"2 \u9897 Intelo Xeon E5-2682V4 CPU\uff0c\u5355\u9897\u5904\u7406\u5668 16 \u6838\u3001\u4e3b\u9891 2.5GHz\"},{\"configName\":\"\u5185\u5b58\",\"value\":\"64G DDR4\"}]",
          "product_area": "\u5c71\u4e1c\u6d4e\u5357",
          "specific_pattern": "",
          "reseller_orgid": "mIWUHBeeDM8mwAFPIQ8pS",
          "del_flg": "0",
          "create_at": "2023-12-13 16:32:28",
          "pay_type": null,
          "resource_type": null,
          "vcpu": null,
          "product_memory": null,
          "hotshow": "1",
          "hotdiscount": null,
          "price": 0.28,
          "unit": "hour",
          "moban": ""
        }
      ],
      isCommon: true,
      dialogVisible: false,
      btnText: '通用计算',
      btnText1: '国产计算',
      btnText2: "免费试用",
      btnText3: '传输专线',
      btnText4: '超算专用网',
      btnText5: '更多产品'
    }
  },
  mounted() {

  },
  methods: {
    goOrigin() {
      window.open(goUrl)

    }
  }
}
</script>

<style scoped lang="scss">
.chaosuan-ul {
  &:hover li {
    color: white !important;
  }
}

.host-grid {


  & > li {
    width: 274px;
    height: 356px;
  }

  & > li {
    margin: 0 35px;
    margin-top: 90px !important;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.item-ul {
  width: 100% !important;
  padding: 5px 0!important;
  li {

    margin: 5px 0;
    width: 100%;
    color: #777684;

    img {
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }

    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

@media screen and (max-width: 760px) {
  .mobile-yun {

  }
  .product-mobile {
    //border: 1px solid red;
    display: flex;
    flex-direction: column;

    & > li {
      margin-bottom: 70px;
    }
  }
  .mobile-style-btn {
    padding-top: 0 !important;
  }
}

.tipStyle {
  width: 200px !important;
}

.title {
  font-size: 18px;

  font-weight: 700;
  color: #4c4948;
}

.on {
}
.header{
  height: 50px;
  padding-top: 20px;
  margin-bottom: 15px;
}
*{
  box-sizing: border-box;
}
</style>
