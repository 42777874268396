<template>
  <div style="width:100%;">
    <ul class="listUl">
      <li
          class="listLi"
          v-for="(item, index) in listInfo"
          :key="item.id"
          :class="{'ji': index % 2 !== 0, 'ou': index % 2 === 0}">
        <div class="content">
          <div class="cStyle">
            <div class="title">{{ item.title }}</div>
            <div class="en">{{ item.en }}</div>
            <div class="description">{{ item.description }}</div>
          </div>
        </div>
        <div class="imgStyle">
          <img :src="item.imgUrl" alt="">
        </div>
      </li>

    </ul>
  </div>
</template>

<script>
export default {
  name: "listBox",
  props:['listInfo'],
  data() {
    return {

    }
  }
}
</script>


<style scoped>
.listUl {

  width: 100%;
}

.listLi {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;

}

.ou {
  background: #eff4fb;
}

.ji {
  background: #fff;
  flex-direction: row-reverse;


}

.content {
  line-height: 1.5;
  width: 50%;
  height: 100%;
  font-size: 14px;

  .title {
    font-size: 26px;
    font-weight: bold;
    color: #4c4948;
  }

  .en {
    color: rgb(169, 169, 169);
    margin: 15px 0;
  }
}

.imgStyle {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width: 60%;
  }
}

.cStyle {
  margin: 100px 80px;
}
</style>