<template>
  <div class="main-box">
    <banner-top :topInfo="topInfo"></banner-top>
    <!--      超算、智算、网络-->
    <div style="margin-top: 0px;padding-top: 50px" class="other-product">
      <ul class="other-product-ul">
        <li :class="{ activeTagStyle: currentTag === '2' }" style="width: 250px;" @click="clickTag('2')">
          <a class="hmc1a aniIconBtn">
            <div class="dsPcPad">
                        <span class="hmc1icon" style="position: relative;height: 150px">
                            <div class="aniIcon"
                                 style="background-image: url('https://www.paratera.com/bingxingweb/images/n3d_2.png'); background-position-y: 0;position: absolute;top: 0;left: -20px"></div>
                            <div class="hmc3nico"><img height="180"
                                                       src="https://www.paratera.com/bingxingweb/images/hmc2nico2.png"
                                                       width="180"></div>
                        </span>
            </div>
            <!--              <div class="dsPhone">-->
            <!--                <span class="mhmc2ico">-->
            <!--                  <img-->
            <!--                    src="https://www.paratera.com/bingxingweb/images/m_hmc2i1.png" width="150" height="150">-->
            <!--                </span>-->
            <!--              </div>-->

            <strong style="margin-top:25px">AI智算</strong>
            <p style="font-size: 14px;width: 100%;padding: 0 5px">
              高效、易用、丰富的GPU算力AI助力产业升级</p>
            <span class="hmc1_lm">了解详情
                <i>&gt;</i>
              </span>
          </a>
        </li>
        <li :class="{ activeTagStyle: currentTag === '1' }" style="width: 250px;"
            @click=" clickTag('1') ">
          <a class="hmc1a aniIconBtn">
            <div class="dsPcPad">
                        <span class="hmc1icon"
                              style="position: relative;height: 150px">
                            <div class="aniIcon"

                                 style="background-image: url('https://www.paratera.com/bingxingweb/images/n3d_1.png'); background-position-y: 0;position: absolute;top: 0;left: -20px"></div>


                            <div class="hmc3nico"><img height="180"
                                                       src="https://www.paratera.com/bingxingweb/images/hmc2nico1.png"
                                                       width="180"></div>
                        </span>
            </div>
            <!--              <div class="dsPhone">-->
            <!--                <span class="mhmc2ico">-->
            <!--                  <img-->
            <!--                    src="https://www.paratera.com/bingxingweb/images/m_hmc2i1.png" width="150" height="150">-->
            <!--                </span>-->
            <!--              </div>-->

            <strong style="margin-top:25px">超算云</strong>
            <p style="font-size: 14px;width: 100%;padding: 0 5px">
              整合各大超算中心计算资源让用户像用电一样方便地使用资源</p>
            <span class="hmc1_lm">了解详情
                <i>&gt;</i>
              </span>
          </a>
        </li>

        <li :class="{ activeTagStyle: currentTag === '3' }" style="width: 250px;" @click="clickTag('3')">
          <a class="hmc1a aniIconBtn">
            <div class="dsPcPad">
                        <span class="hmc1icon" style="position: relative;height: 150px">
                            <div class="aniIcon"
                                 style="background-image: url('https://www.paratera.com/bingxingweb/images/n3d_3.png'); background-position-y: 0;position: absolute;top: 0;left: -20px"></div>


                            <div class="hmc3nico"><img height="180"
                                                       src="https://www.paratera.com/bingxingweb/images/hmc2nico3.png"
                                                       width="180"></div>
                        </span>
            </div>
            <strong style="margin-top:25px">公有云</strong>
            <p class="mobile-description" style="font-size: 14px;width: 100%;padding: 0 10px">
              提供高质量算力网络出口带宽、传输</p>
            <span class="hmc1_lm">了解详情
                <i>&gt;</i>
              </span>
          </a>
        </li>
      </ul>
    </div>


    <div style="width: 100%;display: flex;justify-content: center;align-items: center">
      <div v-if="!isGong" style="width: 100%;background-color: #f2f7fa;padding: 35px">
        <supercomputing-item :product="sendProduct"></supercomputing-item>
      </div>
      <div v-else class="all-cloud">
        <div class="cloud-header" style="margin: 55px 0 25px;">
          <span class="product-index"> 公有云</span>
          <span class="product-description">为开发者及企业提供云服务、云数据、云运营等整体一站式服务</span>
        </div>
        <div class="myDivider" style="margin-bottom: 10px">
          <el-divider style="width: 1200px;"></el-divider>
        </div>
        <div class="new-nav">
          <ul class="nav-ul">
            <li
                :class="{ navLi: item.name === currentTitle }"
                @click="mouseOver($event,item)" v-for="(item,index) in menuArr" :key="item.name">
              <div>
                {{ item.name }}
              </div>
              <div class="tagStyle" v-if="item.tag">
                {{ item.tag }} | {{ item.tag2 }}
              </div>
            </li>
          </ul>
          <el-tabs class="mobileTyle" style="width: 100%;" v-model="pro_type" @tab-click="handleClick">
            <el-tab-pane label="热门产品" name="first"></el-tab-pane>
            <el-tab-pane label="网络" name="second"></el-tab-pane>
            <el-tab-pane label="存储" name="third"></el-tab-pane>
            <el-tab-pane label="安全" name="fourth2"></el-tab-pane>
            <el-tab-pane label="计算" name="fourth3"></el-tab-pane>
            <el-tab-pane label="数据库" name="fourth4"></el-tab-pane>
            <el-tab-pane label="AI能力引擎" name="fourth5"></el-tab-pane>
          </el-tabs>
          <div class="content-r">


            <ul class="bottom-ul" style="display: flex;flex-wrap: wrap;justify-content: center;"
            >
              <li @click="goTwo(item)" v-for="(item,index) in currentData" :key="index"
                  style="width: 300px;margin: 0 15px 10px 15px">
                <a class="sq6Wd6jr">
                  <div class="__9uUkOV59"><h3><span class="LLZ9Q8FX">{{ item.product_name }}   </span></h3>
                    <p class="xQ1YIHP8">{{ item.description }}</p></div>
                  <div class="PfF8K5ib">
                    <div v-for="(itemOne,index) in JSON.parse(JSON.stringify(item.tags))" :key="index"
                         class="_dDwK_qk"><span>{{
                        itemOne
                      }}</span>
                    </div>
                  </div>
                  <div v-if="item.discount" class="n2sHMecG"
                       style="display: flex;justify-content: flex-start;align-items: center">
                  <span class="__1wQVatn_ vh___DYU PEZeG2zA"
                        style="display: inline-block;align-self: flex-end">折扣</span>
                    <span class="__1wQVatn_ cB_ePAPu XeaRmkQC"
                          style="display:inline-block;margin: 0 3px">{{
                        item.discount
                      }} </span>
                    <span class="__1wQVatn_ cB_ePAPu XeaRmkQC"
                          style="align-self:flex-end;display:inline-block;font-size: 14px">折起</span>
                  </div>
                </a>
              </li>
            </ul>

          </div>
        </div>

      </div>
    </div>
    <div>

    </div>
  </div>
</template>

<script>
import './css/main.css'


import SupercomputingItem from "./supercomputingItem/index.vue";
import {goUrl} from "@/utils/settings";
import bannerTop from "@/pages/home/comments/bannerTop/index.vue";

export default {
  name: "suanShop",
  components: {SupercomputingItem, bannerTop},
  data() {
    return {
      topInfo: {
        title: '算力市场',
        description: '为开发者及企业提供云服务、云数据、云运营等整体一站式服务',
        imgUrl: 'https://30093654.s21i.faiusr.com/2/ABUIABACGAAgoKinqgYoxNK9vwUwgAo4pAM.jpg.webp'
      },
      isGong: false,
      pro_type: "first",
      currentTag: '2',
      //菜单
      menuArr: [
        {name: '热门产品', tag: '', tag2: ''},
        {name: '计算', tag: '集群节点一', tag2: '云主机'},
        {name: '网络', tag: 'DDos', tag2: '负载均衡'},
        {name: '存储', tag: '云磁盘', tag2: '文件存储'},
        {name: '安全', tag: '云防火墙', tag2: '风险识别'},

        {name: '数据库', tag: '数据库审计', tag2: '数据库备份DBS'},
        {name: 'AI能力引擎', tag: '文字识别', tag2: '人脸识别'},

      ],
      currentData: [],
      menuData: {
        '热门产品': [
          {
            "type": "bd",
            "product_name": "\u4e91\u5821\u5792\u673a",
            "description": "\u5e2e\u52a9\u4f01\u4e1a\u5b9e\u73b0\u751f\u4ea7\u670d\u52a1\u5668\u7b49IT\u73af\u5883\u7684\u5b89\u5168\u8fd0\u7ef4\u3002",
            "tags": [
              "\u5b89\u5168\u8fd0\u7ef4"
            ],
            "discount": "7.8"
          },
          {
            "type": "bd",
            "product_name": "\u4e91\u6570\u636e\u5e93RDS",
            "description": "\u4e13\u4e1a\u5316\u7684\u9ad8\u53ef\u9760\u3001\u9ad8\u6027\u80fd\u7684\u5173\u7cfb\u578b\u6570\u636e\u5e93\u670d\u52a1\u3002",
            "tags": [
              "\u9ad8\u53ef\u9760",
              "\u9ad8\u6027\u80fd"
            ],
            "discount": "8"
          },
          {
            "type": "bd",
            "product_name": "SSL\u8bc1\u4e66",
            "description": "BaiduTrust\u8d85\u7ea7SSL\u8bc1\u4e66\u62e5\u6709\u591a\u5e74\u7b7e\u53d1\u3001\u514d\u90e8\u7f72\u3001\u8bbf\u95ee\u52a0\u901f\u3001\u641c\u7d22\u52a0\u6743\u7b49\u4f18\u52bf\u6743\u76ca\uff01",
            "tags": null,
            "discount": "8.5"
          },
          {
            "type": "jd",
            "product_name": "\u5bf9\u8c61\u5b58\u50a8",
            "description": "\u81ea\u7814\u7684\u5b58\u50a8\u6d77\u91cf\u6570\u636e\u7684\u5206\u5e03\u5f0f\u5b58\u50a8\u670d\u52a1,\u5f3a\u5b89\u5168\u7684\u4e91\u7aef\u6570\u636e\u5b58\u50a8",
            "tags": null,
            "discount": "7.8"
          },
          {
            "type": "bd",
            "product_name": "\u4e91\u670d\u52a1\u5668_BCC",
            "description": "\u6784\u5efa\u53ef\u5f39\u6027\u4f38\u7f29\u4e91\u8ba1\u7b97\u670d\u52a1\uff0c\u63d0\u4f9b\u8d85\u9ad8\u6548\u8d39\u6bd4\u7684\u9ad8\u6027\u80fd\u4e91\u670d\u52a1\u5668\u3002",
            "tags": [
              "\u53ef\u5f39\u6027\u4f38\u7f29",
              "\u9ad8\u6027\u80fd"
            ],
            "discount": "6.5"
          },
          {
            "type": "jd",
            "product_name": "\u5bf9\u8c61\u5b58\u50a8\u8d44\u6e90\u5305",
            "description": "\u670d\u52a1\u4ebf\u7ea7\u7528\u6237\uff0c\u6570\u636e\u5b58\u50a8\u65e0\u683c\u5f0f\u3001\u65e0\u5bb9\u91cf\u9650\u5236",
            "tags": null,
            "discount": "7"
          },
          {
            "type": "bd",
            "product_name": "NAT\u7f51\u5173",
            "description": "\u4e3a\u4e91\u670d\u52a1\u5668\u5b9e\u73b0\u4ece\u5185\u7f51IP\u5230\u516c\u7f51IP\u7684\u591a\u5bf9\u4e00\u6216\u591a\u5bf9\u591a\u7684\u5730\u5740\u8f6c\u6362\u670d\u52a1\u3002",
            "tags": [
              "\u5171\u4eab\u5e26\u5bbd"
            ],
            "discount": "8.5"
          },
          {
            "type": "jd",
            "product_name": "\u8d1f\u8f7d\u5747\u8861",
            "description": "\u7ba1\u7406\u4fbf\u6377\u7684\u6d41\u91cf\u5206\u53d1\u670d\u52a1\u3002\u8bbf\u95ee\u6d41\u91cf\u7ecf\u7531 LB \u53ef\u81ea\u52a8\u5206\u53d1\u81f3\u591a\u53f0\u670d\u52a1\u5668\u4e0a\uff0c\u63d0\u9ad8\u7cfb\u7edf\u7684\u53ef\u7528\u6027\u5e76\u6269\u5c55\u670d\u52a1\u80fd\u529b",
            "tags": null,
            "discount": "9"
          },
          {
            "type": "jd",
            "product_name": "\u4e91\u786c\u76d8",
            "description": "\u4e91\u4e3b\u673aCVM\u63d0\u4f9b\u4f4e\u65f6\u5ef6\u3001\u9ad8\u53ef\u7528\u3001\u6301\u4e45\u6027\u7684\u5757\u7ea7\u5b58\u50a8\u670d\u52a1",
            "tags": null,
            "discount": "7.5"
          }
        ],
        '网络': [
          {
            product_name: '专线接入',
            description: '专线是一种高性能、安全性极好的网络传输服务',
            tags: ['高性能', '安全性极好'],
            discount: '7.5',
            type: 'bd'
          },
          {
            product_name: '对等连接',
            description: '实现同地域、跨地域，同账户、跨账户之间稳定高速的虚拟网络互联。',
            tags: ['高速的虚拟网络'],
            discount: '',
            type: 'bd'
          },
          {
            product_name: 'IPv6网关',
            description: '为云服务器实现从内网IP到公网IP的多对一或多对多的地址转换服务。',
            tags: ['共享带宽'],
            discount: '7',
            type: 'bd'
          },
          {
            product_name: 'NAT网关',
            description: '为云服务器实现从内网IP到公网IP的多对一或多对多的地址转换服务。',
            tags: ['共享带宽'],
            discount: '',
            type: 'bd'
          },
          {
            product_name: '负载均衡',
            description: '提供高可用的流量分发服务，轻松应对海量访问请求，实现业务水平扩展。',
            tags: ['高性能'],
            discount: '7',
            type: 'bd'
          },
          {
            product_name: 'DDos',
            description: '迅速提升其抗DDoS攻击能力',
            tags: ['全面防护'],
            discount: '',
            type: 'jd'
          },
          {
            product_name: '弹性公网',
            description: '可与云主机 CVM、负载均衡 LB、原生容器 NC、弹性网卡 ENI 等灵活绑定与解绑',
            tags: [],
            discount: '7',
            type: 'jd'
          },
          {
            product_name: '对象存储',
            description: '自研的存储海量数据的分布式存储服务,强安全的云端数据存储',
            tags: ['高可靠', '低成本'],
            discount: '',
            type: 'jd'
          },
          {
            product_name: '数据传输DTS',
            description: '数据库迁移上云，支持数据迁移、数据订阅、数据同步',
            tags: [],
            discount: '8',
            type: 'jd'
          },
        ],
        '存储': [
          {
            product_name: '文件存储',
            description: '为云上的虚机、容器等计算资源提供无限扩展、高可靠、全球共享的文件存储能力',
            tags: ['无限扩展', '高可用'],
            discount: '7.5',
            type: 'bd'
          },
          {
            product_name: '对象存储',
            description: '提供稳定、安全、高效、高可拓展的云存储服务。',
            tags: [],
            discount: '',
            type: 'bd'
          },
          {
            product_name: '云磁盘',
            description: '提供的低时延、持久性、高可靠和高弹性的块存储服务。',
            tags: ['低延迟', '持久性'],
            discount: '7',
            type: 'bd'
          },
          {
            product_name: '内容分发网络',
            description: '由加速节点直接响应用户所需内容，提高用户访问网站资源的响应速度。',
            tags: ['内容分发'],
            discount: '8',
            type: 'bd'
          },
          {
            product_name: '分布式数据库DRDS',
            description: '兼容 MySQL 等特性，适用于高并发、大规模数据的在线交易，历史数据查询，自动数据分片等业务场景',
            tags: ['DRDS'],
            discount: '',
            type: 'jd'
          }, {
            product_name: '数据仓库JDW',
            description: '基于开源的Greenplum提供的在线MPP大规模并行处理数据仓库服务',
            tags: [],
            discount: '',
            type: 'jd'
          }, {
            product_name: '消息队列JCQ',
            description: '分布式消息队列服务，兼容 RocketMQ 等多种协议，得益于底层计算与存储的分离架构',
            tags: ['队列'],
            discount: '7.5',
            type: 'jd'
          }, {
            product_name: '消息队列KafKa',
            description: '基于 Apache Kafka 打造的分布式、高吞吐量、高可扩展性的消息队列服务，是大数据处理不可或缺的组成部分',
            tags: ['即时插拔'],
            discount: '8',
            type: 'jd'
          },
          {
            product_name: '云硬盘',
            description: '云主机CVM提供低时延、高可用、持久性的块级存储服务',
            tags: ['弹性扩展', '挂载/卸载'],
            discount: '',
            type: 'jd'
          },


        ],
        '安全': [
          {
            product_name: '主机安全   ',
            description: '面向企业客户推出的云服务器安全防护产品。',
            tags: ['海量经验', '病毒查杀'],
            discount: '8',
            type: 'bd'
          },
          {
            product_name: '云防火墙',
            description: '自定义防护策略，有效保护用户源站安全。',
            tags: ['自定义防护'],
            discount: '',
            type: 'bd'
          },
          {
            product_name: '云堡垒机',
            description: '帮助企业实现生产服务器等IT环境的安全运维。',
            tags: ['安全运维'],
            discount: '7.5',
            type: 'bd'
          },
          {
            product_name: 'DDoS防护服务',
            description: '能够全面防护各种网络层和应用层的DDoS攻击。',
            tags: ['全面防护'],
            discount: '',
            type: 'bd'
          },
          {
            product_name: 'web应用防火墙',
            description: '对应用进行安全防护与合规性保护的安全产品。',
            tags: [],
            discount: '8',
            type: 'jd'
          },
          {
            product_name: '应用安全网关',
            description: '安全应用网关 （NF1 SAG，Network Fast 1 Security Application Gateway）高流量场景推出的全动态网关。',
            tags: ['安全性高'],
            discount: '',
            type: 'jd'
          }, {
            product_name: '云解析DNS',
            description: '优质网络、主机资源研发的高可用、高可靠、功能丰富的权威DNS服务器。',
            tags: [],
            discount: '8',
            type: 'jd'
          }, {
            product_name: '风险识别',
            description: '即可获取业务中 IP、号码、地址、账号、设备等的画像数据，对其风险进行精确评估。',
            tags: [],
            discount: '',
            type: 'jd'
          }, {
            product_name: '验证码',
            description: '为网页、APP、小程序开发者提供立体、全面的验证码服务。',
            tags: ['多场景'],
            discount: '',
            type: 'jd'
          },


        ],
        '计算': [
          {
            product_name: 'GPU云主机',
            description: '为用户提供稳定、快速、弹性可扩展的计算服务  标签: 图像处理， 4090，多场景应用',
            tags: ['EIP/弹性', 'IP地址'],
            discount: '7.5'
          },
          {
            product_name: 'GPU云服务',
            description: '4090提供优秀的浮点计算能力和超强的并行计算能力的产品 标签：深度学习、科学计算、图形图像处理',
            tags: ['随时扩展', '节约成本'],
            discount: '7'
          },
          {
            product_name: '云服务器_GPU',
            description: '既可提供弹性的GPU云服务器，也可提供高性能的GPU裸金属服务器。',
            tags: ['弹性伸缩', '高性能'],
            discount: '',
            type: 'bd'
          },
          {
            product_name: '云服务器_BCC',
            description: '构建可弹性伸缩云计算服务，提供超高效费比的高性能云服务器。',
            tags: ['高性能'],
            discount: '7',
            type: 'bd'
          },
          {
            product_name: '轻量应用服务器',
            description: '提供官网搭建、web应用搭建、云上学习和测试等场景的服务。',
            tags: ['多场景'],
            discount: '',
            type: 'bd'
          },
          {
            product_name: '微服务平台_注册中心',
            description: '微服务应用的PaaS平台产品，围绕微服务的整个生命周期',
            tags: [],
            discount: '',
            type: 'jd'
          },
          {
            product_name: '性能测试',
            description: '性能测试服务遵循测试服务“云化”的思想，通过对弹性资源的灵活调度，快速实施多种类型的性能测试',
            tags: [],
            discount: '8',
            type: 'jd'
          }, {
            product_name: '云主机',
            description: '云主机（CVM，Cloud Virtual Machine）是一种管理便捷、安全稳定的弹性计算服务',
            tags: ['挂载/卸载'],
            discount: '',
            type: 'jd'
          }, {
            product_name: '队列服务',
            description: '灵活扩缩容的优势。能够轻松处百万级 TPS 的吞吐量',
            tags: [],
            discount: '',
            type: 'jd'
          },

        ],
        '数据库': [
          {
            product_name: '数据可视化私有化   ',
            description: '可按需部署到企业本地服务器或私有云服务器，全面满足您对翻译精准度',
            tags: ['私有化'],
            discount: '7.5',
            type: 'bd'
          },
          {
            product_name: '云数据库RDS',
            description: '专业化的高可靠、高性能的关系型数据库服务。',
            tags: ['高可靠', '高性能'],
            discount: '8',
            type: 'bd'
          },
          {
            product_name: 'MapReduce',
            description: '管理便捷的流量分发服务。访问流量经由 LB 可自动分发至多台服务器上，提高系统的可用性并扩展服务能力',
            tags: ['计算集群'],
            discount: '',
            type: 'bd'
          },
          {
            product_name: '云数据库SCS for Redis',
            description: '计算集群服务，提供高可靠、高安全性、高性价比的分布式计算服务',
            tags: [],
            discount: '8',
            type: 'bd'
          },
          {
            product_name: '数据库审计',
            description: '数据库审计是京东云提供的实时监控数据库安全的审计产品',
            tags: [],
            discount: '',
            type: 'jd'
          }, {
            product_name: '云数据库MongoDB',
            description: '基于开源的 MariaDB 10.2 版本构建的稳定可靠的数据库服务',
            tags: ['灵活', '自动扩展'],
            discount: '7',
            type: 'jd'
          }, {
            product_name: '云数据库MariaDB',
            description: '基于开源的 MariaDB 10.2 版本构建的稳定可靠的数据库服务',
            tags: ['兼容性'],
            discount: '',
            type: 'jd'
          }, {
            product_name: '云数据库Mysql',
            description: '数据库SQL Server是基于微软的SQL Server 打造的适合云端的数据库产品',
            tags: ['安全性', '连接性'],
            discount: '',
            type: 'jd'
          }, {
            product_name: '数据库备份DBS',
            description: '满足业务监管合规要求',
            tags: [],
            discount: '7.5',
            type: 'jd'
          },


        ],
        'AI能力引擎': [
          {
            product_name: '文字识别   ',
            description: '广泛适用于远程身份认证、财税报销、文档电子化等场景，为企业降本增效',
            tags: ['AI识别'],
            discount: '7',
            type: 'bd'
          },
          {
            product_name: '语音能力引擎',
            description: '广泛应用于语音播报，语音会议、智能语音交互等多个业务场景',
            tags: ['高可用'],
            discount: '7.5',
            type: 'bd'
          }, {
            product_name: '自然语言处理',
            description: '提供可直接进行场景应用的NLP语言生成能力，帮助您在多领域快速创作',
            tags: ['NLP'],
            discount: '',
            type: 'bd'
          }, {
            product_name: '图像识别',
            description: '精准识别超过十万种物体和场景',
            tags: ['图像处理'],
            discount: '',
            type: 'bd'
          }, {
            product_name: '图像搜索',
            description: '清晰等维度对图像进行筛选，紧贴业务需求，释放审核人力',
            tags: ['图像筛选'],
            discount: '8',
            type: 'bd'
          }, {
            product_name: '卡证识别   ',
            description: '结构化识别身份证、银行卡、营业执照等常用卡片及证照，支持营业执照信息的准确性核验',
            tags: ['图像识别'],
            discount: '',
            type: 'bd'
          }, {
            product_name: '图像增强与特效',
            description: '满足网络营销、广告活动等多种业务需求',
            tags: ['图像处理'],
            discount: '7.5',
            type: 'bd'
          }, {
            product_name: '人脸识别',
            description: '灵活应用于金融、泛安防等行业场景，满足身份核验、人脸考勤、闸机通行等业务需求',
            tags: ['AI识别'],
            discount: '',
            type: 'bd'
          }, {
            product_name: '机器翻译',
            description: '支持术语定制功能，用户可对翻译结果进行干预，快速提高翻译质量。',
            tags: ['定制功能'],
            discount: '8',
            type: 'bd'
          },
        ],
      },
      currentTitle: '热门产品',
      //公有云京东推荐产品列表
      jd_data:
          {
            title: '京东产品推荐',
            moreProductDescription:
                '京东技术最佳实践，助力产业互联！',
            products:
                [
                  {
                    product_name: '弹性公网IP   ',
                    description: '可与云主机 CVM、负载均衡 LB、原生容器 NC、弹性网卡 ENI 等灵活绑定与解绑',
                    tags: ['EIP/弹性', 'IP地址'],
                    discount: '7.5'
                  },
                  {
                    product_name: '云主机',
                    description: '云主机(CVM，云虚拟机)是一种管理便捷、安全稳定的弹性计算服务',
                    tags: ['随时扩展', '节约成本'],
                    discount: '7'
                  },
                  {
                    product_name: '负载均衡',
                    description: '管理便捷的流量分发服务。访问流量经由 LB 可自动分发至多台服务器上，提高系统的可用性并扩展服务能力',
                    tags: [],
                    discount: '7.5'
                  },
                  {
                    product_name: '容器镜像仓库',
                    description: '库伯奈特(Kubernetes)集群服务无缝集成，为基于容器的应用提供一站式部署服务',
                    tags: ['容器镜像存储'],
                    discount: '7'
                  }
                ]
          }
      ,
      //公有云百度推荐产品列表
      bd_data: {
        title: '百度产品推荐',
        moreProductDescription:
            '全链路的自研能力，形成“云智一体”的独特技术优势',
        products:
            [
              {
                product_name: '云服务器',
                description: '构建可弹性伸缩云计算服务，提供超高效费比的高性能云服务器',
                tags: ['可弹性伸缩', '高性能'],
                discount: '6'
              },
              {
                product_name: '云磁盘',
                description: '提供的低时延、持久性、高可靠和高弹性的块存储服务',
                tags: ['低时延', '持久性'],
                discount: '7'
              },
              {
                product_name: '对象存储',
                description: '提供稳定、安全、高效、高可拓展的云存储服务',
                tags: ['安全', '高扩展'],
                discount: '8'
              },
              {
                product_name: 'GPU服务器',
                description: '既可提供弹性的GPU云服务器，也可提供高性能的GPU裸金属服务器',
                tags: ['计算密集型', '弹性高行能'],
                discount: '7.5'
              }
            ]
      }
      ,
      cap_data: {
        title: '集群节点一',
        moreProductDescription:
            '全链路的自研能力，形成“云智一体”的独特技术优势',
        products:
            [
              {
                product_name: '云服务器',
                description: '构建可弹性伸缩云计算服务，提供超高效费比的高性能云服务器',
                tags: ['可弹性伸缩', '高性能'],
                discount: '6'
              },
              {
                product_name: '云磁盘',
                description: '提供的低时延、持久性、高可靠和高弹性的块存储服务',
                tags: ['低时延', '持久性'],
                discount: '7'
              },
              {
                product_name: '对象存储',
                description: '提供稳定、安全、高效、高可拓展的云存储服务',
                tags: ['安全', '高扩展'],
                discount: '8'
              },
              {
                product_name: 'GPU服务器',
                description: '既可提供弹性的GPU云服务器，也可提供高性能的GPU裸金属服务器',
                tags: ['计算密集型', '弹性高行能'],
                discount: '7.5'
              }
            ]
      }
      ,
      ucloud_data: {
        title: '集群节点二',
        moreProductDescription:
            '全链路的自研能力，形成“云智一体”的独特技术优势',
        products:
            [
              {
                product_name: '云服务器',
                description: '构建可弹性伸缩云计算服务，提供超高效费比的高性能云服务器',
                tags: ['可弹性伸缩', '高性能'],
                discount: '6'
              },
              {
                product_name: '云磁盘',
                description: '提供的低时延、持久性、高可靠和高弹性的块存储服务',
                tags: ['低时延', '持久性'],
                discount: '7'
              },
              {
                product_name: '对象存储',
                description: '提供稳定、安全、高效、高可拓展的云存储服务',
                tags: ['安全', '高扩展'],
                discount: '8'
              },
              {
                product_name: 'GPU服务器',
                description: '既可提供弹性的GPU云服务器，也可提供高性能的GPU裸金属服务器',
                tags: ['计算密集型', '弹性高行能'],
                discount: '7.5'
              }
            ]
      }
      ,
      //超算推荐产品列表
      Supercomputing_product: {
        product_type: 'chaousan',
        products:
            [
              {
                Supercomputing_name: 'CPU节点/1.5T X86-Intel®6258R',
                CPU: '2 颗 Intel® 6258R CPU，单颗处理器 28 核、主频 2.7GHz',
                memory: ' 1.5T DDR4',
                netcard: '2 * 万兆网口; 100Gb/s 高速网卡',
                storage: '2 * 480G SSD 硬盘;2 * 4TB SATA硬盘',
                price: '0.50'
              },
              {
                Supercomputing_name: 'CPU节点/192G X86-Intel®6258R',
                CPU: '2颗Intel® 6258R CPU，单颗处理器28核、主频 2.7GHz',
                memory: '192G DDR4',
                netcard: '2 * 万兆网口;100Gb/s 高速网卡',
                storage: '960G SSD硬盘',
                price: '0.32'
              },
              {
                Supercomputing_name: 'CPU节点/384G X86-Intel®6258R',
                CPU: '2颗Intel® 6258R CPU，单颗处理器28核、主频 2.7GHz',
                memory: '384G DDR4',
                netcard: '2 * 万兆网口;100Gb/s 高速网卡',
                storage: '960G SSD 硬盘',
                price: '0.35'
              },
              {
                Supercomputing_name: 'CPU节点/64GX86-Intel- E5-2682V4',
                CPU: '2颗Intel® 6258R CPU，单颗处理器28核、主频 2.7GHz',
                memory: '384G DDR4',
                netcard: '2 * 万兆网口;100Gb/s 高速网卡',
                storage: '960G SSD 硬盘',
                price: '0.35'
              },
            ]
      }
      ,
      Zhisuan_product: {
        product_type: 'chaousan1',
        products:
            [
              {
                Supercomputing_name: '4090配置A',
                CPU: 'AMD EPYC 7542 2.9GHz',
                memory: ' NVIDIA GeForce RTX 4090（显存：24G）',
                netcard: '2 * 万兆网口; 100Gb/s 高速网卡',
                storage: '2 * 480G SSD 硬盘;2 * 4TB SATA硬盘',
                price: '0.50',
                path: '/product/gpu/createGpu'
              },
              {
                Supercomputing_name: '4090配置B',
                CPU: 'Intel Xeon 6430 2.1GHz',
                memory: 'NVIDIA GeForce RTX 4090（显存：24G）',
                netcard: '2 * 万兆网口;100Gb/s 高速网卡',
                storage: '440G SSD硬盘',
                price: '0.32',
                path: '/product/ucloudProduct/createCloudHost'
              },
              {
                Supercomputing_name: 'N-A800-80N-A01',
                CPU: 'AMD EPYC 7453 2.8GHz',
                memory: 'NVIDIA Tesla A100 SXM（显存：80G）',
                netcard: '2 * 万兆网口;100Gb/s 高速网卡',
                storage: '560 SSD硬盘',
                price: '0.32',
              },
              {
                Supercomputing_name: 'N-V100-40P-I01',
                CPU: 'Intel Xeon Gold 6348 2.6GHz',
                memory: 'NVIDIA Tesla A100 PCIE（显存：40G）',
                netcard: '2 * 万兆网口;100Gb/s 高速网卡',
                storage: '440 SSD硬盘',
                price: '0.32',
              },
              {
                Supercomputing_name: 'N-A100-80N-A02',
                CPU: 'AMD EPYC 7763 2.45GHz',
                memory: 'NVIDIA Tesla A100 SXM（显存：80G）',
                netcard: '2 * 万兆网口;100Gb/s 高速网卡',
                storage: '860G SSD硬盘',
                price: '0.32',
              },
              {
                Supercomputing_name: 'N-H800-80-I01',
                CPU: 'Intel Xeon Platinum 8468 2.1GHz',
                memory: 'NVIDIA Tesla H800 SXM（显存：80G）',
                netcard: '2 * 万兆网口;100Gb/s 高速网卡',
                storage: '960G SSD硬盘',
                price: '0.32',
              },

              // {
              //   Supercomputing_name: 'N-A100-80N-A01',
              //   CPU: 'AMD EPYC 7453 2.8GHz',
              //   memory: 'NVIDIA Tesla A100 SXM（显存：80G）',
              //   netcard: '2 * 万兆网口;100Gb/s 高速网卡',
              //   storage: '960G SSD硬盘',
              //   price: '0.32',
              // },
              // {
              //   Supercomputing_name: 'N-H800-80-I01',
              //   CPU: 'Intel Xeon Platinum 8468 2.1GHz',
              //   memory: 'NVIDIA Tesla H800 SXM（显存：80G）',
              //   netcard: '2 * 万兆网口;100Gb/s 高速网卡',
              //   storage: '960G SSD硬盘',
              //   price: '0.32',
              // },
            ]
      }
      ,
      network: {
        product_type: 'wangluo',
      }
      ,
      sendProduct: {}
      ,
      showMoreFlag: false
    }
  },
  mounted() {
    this.sendProduct = this.Zhisuan_product
    this.currentData = this.menuData[this.currentTitle]
  },
  methods: {
    handleClick(val) {
      let b = {
        name: val.label
      }
      this.mouseOver('a', b)
      console.log("@@@@", val)
    },
    clickTag(value) {

      this.currentTag = value
      if (value === '1') {
        this.isGong = false
        this.sendProduct = {}
        this.sendProduct = this.Supercomputing_product
        // this.$router.push('/product/superComputingCommon')
      } else if (value === '2') {
        this.isGong = false
        this.sendProduct = {}
        this.clickZhisuan("2")
      } else if (value === '3') {
        // this.sendProduct = this.network
        // window.open('http://cloud.opencomputing.cn/user/login')
        this.isGong = true
      }
    },
    clickZhisuan(value) {
      this.currentTag = value
      this.sendProduct = this.Zhisuan_product
    },
    mouseOver(a, b) {
      console.log(a, b.name)
      this.currentTitle = b.name
      this.currentData = this.menuData[b.name]
    },
    goTwo() {
      window.open(goUrl)
    }
  }
}
</script>

<style lang="scss" scoped>
.showMore {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.main-box {
  font-size: 14px;
  background-color: white;
}

.all-cloud {
  margin-bottom: 25px;
}

.cloud-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80px;
}

.cloud-left {
  display: flex;
  flex-direction: column;
}

.jd-box {

}

.all-cloud-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-index {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: PingFangSC-Medium;
  font-size: 28px;
  color: #222;
  text-align: center;
  line-height: 40px;
  font-weight: 600;
}

.product-description {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: rgba(34, 34, 34, .9);
  text-align: center;
  line-height: 24px;
  font-weight: 400;
}

.other-product {
  margin-top: 35px;
  width: 100%;
  background-color: #f2f7fa;

}

.activeTagStyle {
  background: #bcbce4;
  color: #fff !important;

  * {
    color: #fff !important;
  }
}

.other-product-ul {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;


  li {
    margin: 0 55px;
    border-radius: 12px;
    //padding-left: 15px;
    //padding-right: 15px;

    * {
      transition: all .3s ease-in-out;
    }

    transition: all .3s ease-in-out;

    &:hover {
      cursor: pointer;

      a {
        //background: #bcbce4;
        border-radius: 12px;
      }

      img {
        filter: brightness(0) invert(1) !important; /* 将图像变为白色 */
      }

      * {
        transition: all .3s ease-in-out;
        color: #fff !important;

      }

      transition: all .3s ease-in-out;
      scale: 1.03;
      background: #bcbce4;
    }
  }
}

//mobile
@media screen and (max-width: 760px) {
  .new-nav {
    overflow-x: auto;
  }
  .main-box {

  }
  .cloud-header {
    margin: 0 0 15px 0 !important;
  }
  .mobile-banner {
    display: none;
  }
  .all-cloud-item {

    display: flex;
    flex-direction: column;
  }
  .other-product-ul {

    li {
      width: 33.33% !important;
      margin: 0;

      .dsPcPad {
        display: none !important;

        .mobile-description {
          display: none !important;
        }
      }
    }
  }

}

.new-nav {
  padding: 0 50px;
  //box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .content-r {
    width: calc(100% - 300px);
    height: 700px;
    overflow-y: auto;
  }
}

.navLi {
  position: relative;
  color: #fff;

  .tagStyle {
    color: #fff !important;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(270deg, rgba(36, 104, 242, 0), #2468f2);
    opacity: 1 !important;
    transition: opacity .15s;
    z-index: 1;
  }
}

.nav-ul {

  .tagStyle {
    color: rgba(34, 34, 34, .7);
    font-weight: 400;
    margin-top: 10px;
  }

  padding-top: 12px;
  width: 283px;
  height: 596px;
  border: 1px solid rgba(34, 34, 34, .04);
  background: #f2f6fa url("https://bce.bdstatic.com/p3m/common-service/uploads/product_new_fca5b43.png") no-repeat 100% 100%;
  background-size: contain;

  li {
    padding-left: 15px;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    font-weight: bold;
    z-index: 1;
    //padding: 10px 0 0 10px;
    &:first-child {
      height: 60px;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background-image: linear-gradient(270deg, rgba(36, 104, 242, 0), #2468f2);
      opacity: 0;
      transition: opacity .15s;
      z-index: -1;
    }

    &:hover {
      cursor: pointer;
      color: #fff;

      .tagStyle {
        color: #fff;
      }

      &:after {
        opacity: 1;
      }
    }
  }
}

.bottom-ul {
  display: flex;
  justify-content: flex-start !important;
  align-items: center !important;
}

.bottom-ul li.W9D5W9Bi a.sq6Wd6jr {
  padding: 16px;
  position: relative;
}

.__9uUkOV59 {
  height: 84px;
  overflow: hidden;
  margin-bottom: 7px;
}

.LLZ9Q8FX {
  font-family: PingFangSC-Semibold;
  font-size: 18px;
  color: #222;
  line-height: 28px;
  font-weight: 600;
  margin-right: 8px;
}

.xQ1YIHP8 {
  margin-top: 8px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #222;
  opacity: .9;
  line-height: 24px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; /* 控制最大行数 */
}

* {
  list-style: none;
  margin: 0;
  padding: 0;
}

.PfF8K5ib {
  display: flex;

}

.n2sHMecG {
  display: flex;
  align-items: flex-end;
  margin-top: 17px;
}

._dDwK_qk {
  border: 1px solid rgba(34, 34, 34, .4);
  border-radius: 2px;
  padding: 0 7px;
  margin-right: 8px;
  color: #222;
  text-align: center;
  line-height: 0px
}

._dDwK_qk:last-child {
  margin-right: 0
}

._dDwK_qk span {
  opacity: .6;
  font-size: 12px;
  font-family: PingFangSC-Medium;
  line-height: 22px;
  font-weight: 600
}

.__1wQVatn_.vh___DYU {
  opacity: .9;
  color: #222;
  font-family: PingFangSC-Regular;
}

.cB_ePAPu {
  color: #f33e3e;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
}

.PEZeG2zA {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.XeaRmkQC {
  font-size: 22px;
}

.bottom-ul li.__9_Vr3zVM .rm5E9XJs .hi0gVRei:hover {
  background: #4f85f0;
  transition: all .3s ease-out
}

a:hover {
  text-decoration: none;
  outline: 0;
}

.bottom-ul li:hover {
  box-shadow: 0 6px 16px 2px rgba(7, 12, 20, .08);
  transform: translateY(-8px);
}

.bottom-ul li {
  transition: all .3s ease-out;
  height: 188px;
  background: #f2f6fa;
  border-radius: 4px;
  border: 1px solid rgba(34, 34, 34, .04);
  position: relative;
  padding: 15px;
  margin: 0 10px;
  margin-bottom: 15px;
}

.__8aghIOpn.R1JAzgJE::before {
  opacity: 1;
}

.__8aghIOpn::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(270deg, rgba(36, 104, 242, 0), #2468f2);
  opacity: 0;
  transition: opacity .15s;
}

.mobileTyle {
  display: none;
}

@media screen and (max-width: 760px) {
  .myDivider {
    display: none;
  }
  .new-nav {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .nav-ul {
      display: none;
    }

    .mobileTyle {
      display: block;
    }

    .content-r {
      width: 100%;
      height: auto;

      .bottom-ul {

        padding: 0 10px;
        display: grid !important;
        grid-template-columns: 1fr 1fr !important; /* 两列等宽 */
        //justify-content: space-around !important;
        gap: 10px; /* 上下左右间距 */
        li {
          margin-right: 0 !important;
          margin-left: 0 !important;
          margin-bottom: 0 !important;
          width: 100% !important;
        }
      }
    }
  }
}
</style>
