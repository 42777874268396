<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import {
  ThemeColor
} from './ThemeColor'
import {reqGetTestData} from "@/api";
import './pages/css/reset.css'
export default {
  name: 'App',
  components: {},
  mounted() {
    this.setRemUnit()
    this.initHammer()
  },
  methods: {
    setRemUnit() {
      const rem = 75; // 设置基准字体大小（默认为16px）
      const screenWidth = window.innerWidth;
      const designWidth = 1920; // 设置设计稿的宽度，假设为PC端设计稿的宽度

      // 计算当前屏幕相对于设计稿的缩放比例
      const scale = screenWidth / designWidth;

      // 计算并设置根元素的字体大小（rem单位）
      document.documentElement.style.fontSize = rem * Math.min(scale, 2) + 'px';

    },
// const key = computed(() => {
//   return route.path + Math.random();
// });
    initHammer() {
      const el = document.getElementById('app');
      const mc = new Hammer.Manager(el);

      // 添加缩放手势
      mc.add(new Hammer.Pinch());

      // 监听缩放手势的事件
      mc.on("pinch", (event) => {
        this.handlePinch(event.scale);
      });
    },

  }
}
</script>

<style>
#app{
  width: 100%;
}
</style>