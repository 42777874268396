

<template>
  <div>
    <BannerTop :topInfo="topInfo"></BannerTop>
    <ListBox :listInfo="listInfo"></ListBox>
  </div>
</template>
<script>
import BannerTop from "@/pages/home/comments/bannerTop/index.vue";
import ListBox from "@/pages/home/comments/listBox/index.vue";

export default {
  name: "cxy",
  components: {ListBox, BannerTop},
  data(){
    return{
      topInfo:{
        title:'高校科研解决方案',
        description:'大部分高校的高性能计算中心或网络中心缺少专业的HPC技术人员。课题老师专注科研领域，对HPC平台的计算机知识不熟。平台缺乏有效管理手段，用户体验和资源利用率较低',
        imgUrl:'https://30093654.s21i.faiusr.com/2/ABUIABACGAAgk-uxqgYo7MD0vgYwgA849gQ.jpg.webp'
      },
      listInfo:[
        {
          id: '1',
          title: '方案亮点',
          description: '深入分子动力学、蛋白质结构预测，计算机辅助药物设计、生物结构测定、生物信息学等高算力应用场景，提供一站式平台服务，优化应用设计开发效率， 屏蔽底层IT技术细节，高效生命科研计算；最低的价格实现最高的计算效率；业务流全栈定制化服务。',
          en: 'SHINING POINT',
          imgUrl: 'https://30093654.s21i.faiusr.com/4/ABUIABAEGAAgn-_lqgYo2PGHzwcw9QM4uQI.png.webp'
        },
        {
          id: '2',
          title: '方案亮点',
          description: '深入分子动力学、蛋白质结构预测，计算机辅助药物设计、生物结构测定、生物信息学等高算力应用场景，提供一站式平台服务，优化应用设计开发效率， 屏蔽底层IT技术细节，高效生命科研计算；最低的价格实现最高的计算效率；业务流全栈定制化服务。',
          en: 'SHINING POINT',
          imgUrl: 'https://30093654.s21i.faiusr.com/4/ABUIABAEGAAgn-_lqgYo2PGHzwcw9QM4uQI.png.webp'
        },
        {
          id: '3',
          title: '方案亮点',
          description: '深入分子动力学、蛋白质结构预测，计算机辅助药物设计、生物结构测定、生物信息学等高算力应用场景，提供一站式平台服务，优化应用设计开发效率， 屏蔽底层IT技术细节，高效生命科研计算；最低的价格实现最高的计算效率；业务流全栈定制化服务。',
          en: 'SHINING POINT',
          imgUrl: 'https://30093654.s21i.faiusr.com/4/ABUIABAEGAAgn-_lqgYo2PGHzwcw9QM4uQI.png.webp'
        },
        {
          id: '4',
          title: '方案亮点',
          description: '深入分子动力学、蛋白质结构预测，计算机辅助药物设计、生物结构测定、生物信息学等高算力应用场景，提供一站式平台服务，优化应用设计开发效率， 屏蔽底层IT技术细节，高效生命科研计算；最低的价格实现最高的计算效率；业务流全栈定制化服务。',
          en: 'SHINING POINT',
          imgUrl: 'https://30093654.s21i.faiusr.com/4/ABUIABAEGAAgn-_lqgYo2PGHzwcw9QM4uQI.png.webp'
        }
      ]
    }
  }
}
</script>
<style scoped>

</style>