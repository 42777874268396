<template>
  <div>
    <banner-top :topInfo="topInfo"></banner-top>
    <div class="main">
      <div class="introduce">
        <div class="title">企业介绍</div>
        <div class="en">ABOUT</div>
      </div>
      <div class="content">
        <p>
          <span class="title"> 开元云（北京）科技有限公司</span>，是一家注册于2020年的高科技企业，在上海、南京、深圳、济南等地设有分支机构，创始团队核心成员来自一流的云计算公司及电信运营商，拥有云计算、超算、智算和网络运营专业经验，在企业市场均拥有超过十年以上行业经验，服务客户超过2万家。公司以自主研发的业务操作支撑系统（KBoss）为底座，打造开放算力应用服务平台（open-computing），将云计算、算力资源和算力应用进行整合，为高校、科研、大模型、AI等政企客户提供专业算力云服务，形成“云+网+算+应用”的一体化解决方案。
        </p>
        <p>
          在2021年，我们荣幸地成为阿里云计算的合作伙伴，致力于提供算力应用、算力网络、算网一体的产品和服务，同时为芯片、教育科研等企业提供优质的算力服务。2022年，我们与国家超级计算济南中心以及中信网络有限公司签署了战略合作协议，并成功推出了“Kboss”算网平台。在2023年，我们的平台进一步发展，成功引入火山引擎、百度智能云。目前，我们已成为阿里云、江苏未来网络集团的战略合作伙伴。同时，我们深耕“算力+教育”赛道，持续推进高校算力平台项目，积极建设学校算力网络节点，目前已经成功开拓了27所高校。</p>
        <p>
          公司提供新一代算力云应用服务模式，通过自主研发的开元算力云应用服务平台，整合算力资源和算法应用，利用创新算力调度化和确定性网络技术，针对现代社会对智能化和数字化需求，形成包括算力云服务、算力网络和算力应用的全场景解决方案。旨在为政府和企业提供"技术+资源+场景+运营”的产业互联网算力云应用服务平台，实现以算力云服务推动数字经济的发展。开元云科技自成立以来得到了包括工信部、教育部、全国高校学会、国家超算中心以及南京未来网络研究院等政府机构、科研机构的大力支持，合作领域包括“东数西算、大科学计算、存算分离、芯算一体及国产工业软件SaaS化”，覆盖人工智能、芯片仿真、生物制药、工业仿真、材料研发、精尖制造、海洋勘探以及气象监测等高科技领域。</p>
        <img src="https://30093654.s21i.faiusr.com/4/ABUIABAEGAAgm-_lqgYo_sD07wcwkAg41AI.png.webp" alt="">
      </div>
      <div class="introduce">
        <div class="title">员工风采</div>
        <div class="en">STAFF</div>
      </div>

      <div style="width:100%;max-width:1200px;display: flex">
        <div class="img-l">
          <div class="l-t">
            <img src="https://30093654.s21i.faiusr.com/2/ABUIABACGAAg2sWrqgYomMixATCgCzi4CA!400x400.jpg.webp" alt="">
            <img src="https://30093654.s21i.faiusr.com/2/ABUIABACGAAg2sWrqgYorY7uyQIwoAs4uAg!400x400.jpg.webp" alt="">
          </div>
          <img style="width: 780px;height: 467px;margin-top: 55px" src="https://30093654.s21i.faiusr.com/4/ABUIABAEGAAg2sWrqgYoxIeRqwUwoQY43wM!800x800.png.webp" alt="">
        </div>
        <div class="img-r">
          <img style="height: 467px;" src="https://30093654.s21i.faiusr.com/2/ABUIABACGAAg6unGqgYoz8CLGTC4CDizCg!500x500.jpg.webp" alt="">
          <img style="margin-top: 15px" src="https://30093654.s21i.faiusr.com/2/ABUIABACGAAg2sWrqgYo2LqitQEwuAg4gwg!400x400.jpg.webp" alt="">
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import BannerTop from "@/pages/home/comments/bannerTop/index.vue";

export default {
  name: "about",
  components: {BannerTop},
  data() {
    return {
      topInfo:{
        title:'关于我们',
        description:'走进开元云 | 加入开元云',
        imgUrl:'https://30093654.s21i.faiusr.com/2/ABUIABACGAAg-6_nqgYogO2FjgcwgAo4pAM.jpg.webp'
      },
    }
  }
}
</script>


<style scoped lang="scss">
.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 35px;
}

.introduce {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 25px 0;
}

.title {
  font-size: 26px;
}

.en {
  font-size: 16px;
  margin-top: 10px;
  color: rgb(169, 169, 169);
}

.content {
  width: 100%;
  max-width: 1200px;
  font-size: 16px;
  line-height: 1.5;
  color: #666666;

  img {
    width: 100%;
    margin: 15px 0;
  }

  .title {
    font-weight: bold;
    color: #4c4948;
    font-size: 16px;
  }
}
.img-l{
  display: flex;
  flex-direction: column;

  margin-right: 15px;
}
.img-r{
  display: flex;
  flex-direction: column;
  img{
    width: 360px;
  }
}
.l-t{
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  img{
    width: 380px;
    height: 285px;
  }
}
</style>