<template>
    <div class="hello">
        <h1>我是hello</h1>
    </div>
</template>

<script>
export default {
    name: 'HelloWorld',
    props: {
        msg: String
    }
}
</script>

<style scoped>

</style>
