

<template>
<div>
  <BannerTop :topInfo="topInfo"></BannerTop>
  <ListBox :listInfo="listInfo"></ListBox>
</div>
</template>
<script>
import BannerTop from "@/pages/home/comments/bannerTop/index.vue";
import ListBox from "@/pages/home/comments/listBox/index.vue";

export default {
  name: "zs",
  components: {ListBox, BannerTop},
  data(){
    return{
      topInfo:{
        title:'智算中心',
        description:'提供算力租用、服务器定制、超算集群建设等资源和运维',
        imgUrl:'https://30093654.s21i.faiusr.com/2/ABUIABACGAAglPuxqgYopKnPyAMwgA849gQ.jpg.webp'
      },
      listInfo:[
        {
          id: '1',
          title: '建设',
          description: '深入分子动力学、蛋白质结构预测，计算机辅助药物设计、生物结构测定、生物信息学等高算力应用场景，提供一站式平台服务，优化应用设计开发效率， 屏蔽底层IT技术细节，高效生命科研计算；最低的价格实现最高的计算效率；业务流全栈定制化服务。',
          en: 'SHINING POINT',
          imgUrl: 'https://img0.baidu.com/it/u=4143086829,1957172416&fm=253&fmt=auto&app=138&f=JPEG?w=893&h=500'
        },
        {
          id: '2',
          title: '运维',
          description: '深入分子动力学、蛋白质结构预测，计算机辅助药物设计、生物结构测定、生物信息学等高算力应用场景，提供一站式平台服务，优化应用设计开发效率， 屏蔽底层IT技术细节，高效生命科研计算；最低的价格实现最高的计算效率；业务流全栈定制化服务。',
          en: 'SHINING POINT',
          imgUrl: 'https://img0.baidu.com/it/u=2027346347,2533080347&fm=253&fmt=auto&app=138&f=JPEG?w=750&h=500'
        },
        {
          id: '3',
          title: '驾驶舱',
          description: '深入分子动力学、蛋白质结构预测，计算机辅助药物设计、生物结构测定、生物信息学等高算力应用场景，提供一站式平台服务，优化应用设计开发效率， 屏蔽底层IT技术细节，高效生命科研计算；最低的价格实现最高的计算效率；业务流全栈定制化服务。',
          en: 'SHINING POINT',
          imgUrl: 'https://img2.baidu.com/it/u=1128157025,1721218114&fm=253&fmt=auto&app=120&f=JPEG?w=852&h=500'
        },
      ]
    }
  }
}
</script>
<style scoped>

</style>