<template>
<h1>我是demoTwo</h1>
</template>

<script>
export default {
    name: "demoTwo"
}
</script>

<style scoped>

</style>