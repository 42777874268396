<template>
  <div :style="{ backgroundImage: `url(${topInfo.imgUrl})` }" class="main-box">
<div class="content">
      <div class="title">

  {{topInfo.title}}
</div>
  <div class="description">{{topInfo.description}}</div>
</div>
  </div>
</template>
<script>
export default {
  name: "bannerTop",
  props:['topInfo']
}
</script>


<style scoped>
.main-box {
  margin-top: 60px;
  width: 100%;
  height: 420px;
  background-size: cover;

  position: relative;
}
.content{
  position: absolute;
  top:150px;
  left:150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.title {
  font-size: 24px;
  margin-bottom: 25px;
  font-weight: 550;
  color: #4c4948;
}
.description{
  font-size: 14px;

  width: 400px;
  line-height: 1.5;
}
</style>